import { DynamicFormDefaultValue } from '@common/submission-data';
import { isEmpty } from 'lodash';
import {
  Dispatch,
  FC,
  forwardRef,
  Fragment,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { Divider, LoaderOverlay, Stack, Typography } from '@common-components';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { QuoteExits } from 'clients/types';
import { CoverageLine } from 'enums';
import { ExtractedDataFields, useBoolean, useFormProvider, useSearchInsuranceProduct, useToast } from 'hooks';
import { messages } from 'i18n';
import { triggerCustomError } from 'utils';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import { SuggestionProps } from 'components/hookFormComponents/types';
import FormWithStepper from 'broker/components/common/FormWithStepper';
import ScrollFormIndicator from 'broker/components/common/ScrollFormIndicator';
import SubmissionFooter from 'broker/components/common/SubmissionFooter';
import { MainAreaDimensionsState } from 'broker/components/common/VerticalFormStepper/types';
import { useScrollButtonVisibility } from 'broker/hooks';
import { AIReason } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIReason';
import { DynamicFormFieldApiError } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/types';
import CoverageLinesChips from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/components/CoverageLinesChips';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useIsBORFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import DynamicFormArrayFields, {
  BaseDynamicFormArrayFieldsProps,
  BaseDynamicFormFieldsValue,
  defaultDynamicFormAddressArrayValue,
  defaultDynamicFormClaimEventArrayValue,
} from './components/DynamicFormArrayFields';
import { getDynamicValueFromFieldPath } from './components/utils/utils';
import mapDynamicQuestionToFormComponent from './mappers/map-dynamic-question-to-form-component';
import mapDynamicQuestionsToYupShape from './mappers/map-dynamic-questions-to-yup-shape';
import { DynamicFormImperativeHandle, DynamicFormState, DynamicQuestion } from './types';
import { useGetDynamicFormComputedData } from './useGetDynamicFormComputedData';
import { useGetSectionsIndicators } from './useGetSectionsIndicators';
import { dynamicFormInputTypesConfig } from './utils/dynamic-form-input-types-config';
import { getExtractionValidationFields } from './utils/extraction-validation-fields';
import { formValidationErrorExceptionMessage, scrollToFirstSuggestion } from './utils/form-utils';
import { getNormalizeDynamicFormStateAccordingToSchema } from './utils/schema-utils';
import { useGetAnsweredDynamicQuestionsBySections } from './utils/useGetAnsweredDynamicQuestionsBySections';

interface DynamicFormLayoutProps {
  dynamicQuestions: DynamicQuestion[];
  onFormSubmit: (data: DynamicFormState) => Promise<void>;
  setIsDirty?: (isDirty: boolean) => void;
  activeSection?: string;
  setActiveSection: (section?: string) => void;
  onClose: () => void;
  mainAreaDimensionsState?: MainAreaDimensionsState;
  setScrollPositionOnMount?: (number?: number) => void;
  scrollPositionOnMount?: number;
  setScrollPosition?: (number?: number) => void;
  scrollPosition?: number;
  isAcknowledgmentFlow: boolean;
  submissionExtractedData?: ExtractedDataFields;
  applySuggestionsOnEmptyFields?: boolean;
  sectionRefs: MutableRefObject<Record<string, HTMLDivElement | null>>; // New prop added here
  scrollableDivRef: MutableRefObject<HTMLDivElement | null>;
  setNextStep: (props?: SetNextStepsProps) => void;
  coverageLines?: CoverageLine[];
  quoteExits?: QuoteExits[];
  extractionValidationFields: Record<string, boolean>;
  setExtractionValidationFields: Dispatch<SetStateAction<Record<string, boolean>>>;
  heraldProducts?: string[];
  isHeraldSubmission: boolean;
  dynamicFormDefaultValues: Record<string, DynamicFormDefaultValue>;
}

export const DynamicFormLayout = forwardRef<DynamicFormImperativeHandle, DynamicFormLayoutProps>(
  (
    {
      dynamicQuestions,
      submissionExtractedData,
      onFormSubmit,
      setIsDirty,
      activeSection,
      setActiveSection,
      onClose,
      mainAreaDimensionsState,
      scrollPositionOnMount,
      setScrollPositionOnMount,
      scrollPosition,
      setScrollPosition,
      isAcknowledgmentFlow,
      applySuggestionsOnEmptyFields,
      sectionRefs,
      scrollableDivRef,
      quoteExits,
      setNextStep,
      coverageLines,
      extractionValidationFields,
      setExtractionValidationFields,
      heraldProducts = [],
      isHeraldSubmission,
      dynamicFormDefaultValues,
    },
    ref,
  ) => {
    const { showToast } = useToast();
    const { isBOR, isBOROnboarding } = useIsBORFlow();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const buttonClickedRef = useRef(false);

    const { items: products } = useSearchInsuranceProduct();

    const [highlightValidationRequired, { on: highlightValidationRequiredOn, off: highlightValidationRequiredOff }] =
      useBoolean(false);

    const { showScrollButton, handleScrollDown } = useScrollButtonVisibility({ ref: scrollableDivRef, sectionRefs });

    const { dynamicQuestionsBySections, defaultValues, sectionList } = useGetDynamicFormComputedData({
      dynamicQuestions,
    });

    const { methods, appliedSuggestions } = useFormProvider({
      schema: mapDynamicQuestionsToYupShape(dynamicQuestions),
      defaultValues,
      setIsDirty,
      suggestedValues: submissionExtractedData,
      applySuggestionsOnEmptyFields,
    });

    const resolvedAppliedSuggestions = useMemo(
      () =>
        Object.entries(appliedSuggestions).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value,
            reason: <AIReason suggestion={value} />,
          };
          return acc;
        }, {} as Record<string, SuggestionProps>),
      [appliedSuggestions],
    );

    // set extractionValidationFields to false for all the fields that are required and have not been answered
    useEffect(() => {
      if (!isAcknowledgmentFlow) {
        return;
      }

      setExtractionValidationFields((prev) => {
        const validationFields = getExtractionValidationFields(coverageLines || []).reduce(
          (acc, extractionValidationField) => {
            // if user already validated the extraction, then we want to keep this knowledge as this component is mounted after sending the form
            if (prev[extractionValidationField]) {
              acc[extractionValidationField] = true;
            }
            // check if extractionValidationField exist in resolvedAppliedSuggestions
            else if (extractionValidationField in resolvedAppliedSuggestions) {
              acc[extractionValidationField] = false;
            }
            return acc;
          },
          {} as Record<string, boolean>,
        );
        // build object with keys from validationFields, if the key exists in prev, use the value from prev, otherwise use the value from validationFields
        return Object.keys(validationFields).reduce((acc, key) => {
          acc[key] = key in prev ? prev[key] : validationFields[key];
          return acc;
        }, {} as Record<string, boolean>);
      });
    }, [coverageLines, isAcknowledgmentFlow, resolvedAppliedSuggestions, setExtractionValidationFields]);

    const watchedFormValues = methods.watch();

    const answeredDynamicQuestionsBySections = useGetAnsweredDynamicQuestionsBySections({
      formValues: watchedFormValues,
      dynamicQuestionsBySections,
    });

    const formValues = methods.getValues();

    const { sectionsWithErrors } = useGetSectionsIndicators({
      activeSection,
      methods,
      sectionList,
      answeredDynamicQuestionsBySections,
      dynamicQuestions,
    });

    useLayoutEffect(() => {
      if (typeof scrollPosition !== 'undefined') {
        // After submission is complete, scroll back to the stored position
        scrollableDivRef.current?.scrollTo({ top: scrollPosition, behavior: 'instant' });
        setScrollPosition?.(undefined);
      }
    }, [scrollPosition, scrollableDivRef, setScrollPosition]);

    useLayoutEffect(() => {
      if (typeof scrollPositionOnMount !== 'undefined') {
        // Scroll the div to the stored position
        scrollableDivRef.current?.scrollTo({ top: scrollPositionOnMount, behavior: 'instant' });
        // Reset the scroll position without triggering re-renders
        if (scrollPositionOnMount !== undefined) {
          setScrollPositionOnMount?.(undefined); // Ensure this line does not cause re-renders
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isErrorFromFormField = (e: any) =>
      e.message === formValidationErrorExceptionMessage || e instanceof DynamicFormFieldApiError;

    const onSubmit = async (data: DynamicFormState, displayToast = true) => {
      setIsSubmitting(true);
      const currentPosition = scrollableDivRef.current?.scrollTop;
      setScrollPositionOnMount?.(currentPosition);
      if (setIsDirty) {
        setIsDirty(false);
      }
      try {
        // normalizing the form values according to its schema
        const normalizeDynamicFormStateValues = getNormalizeDynamicFormStateAccordingToSchema(data, dynamicQuestions);
        await onFormSubmit(normalizeDynamicFormStateValues);
        setIsSubmitting(false);
        if (displayToast && !isAcknowledgmentFlow) {
          showToast('success', { message: messages.submissionModal.submissionUpdated });
        }
      } catch (e: any) {
        setIsSubmitting(false);
        if (setIsDirty) {
          setIsDirty(true);
        }
        if (e instanceof ForbiddenError) {
          showToast('error', { message: e.message });
        } else if (e instanceof DynamicFormFieldApiError) {
          triggerCustomError(methods, e.path, e.message);
        } else {
          showToast('error', { message: messages.editSubmission.serverError });
          // eslint-disable-next-line no-console
          console.error(e);
        }
        throw e;
      }
    };

    const submitForm = async (isSubmitFromOnBlur = false) => {
      if (!isSubmitFromOnBlur) {
        buttonClickedRef.current = true;
      }
      try {
        await new Promise((resolve, reject) => {
          methods.handleSubmit(
            async (data) => {
              try {
                const response = await onSubmit(data, !isSubmitFromOnBlur);
                resolve(response);
              } catch (e) {
                reject(e);
              }
            },
            () => {
              reject(new Error(formValidationErrorExceptionMessage));
            },
          )();
        });
      } finally {
        if (!isSubmitFromOnBlur) {
          buttonClickedRef.current = false;
        }
      }
    };

    const getFormArrayDefault = (dynamicQuestion: DynamicQuestion): BaseDynamicFormFieldsValue => {
      const config = dynamicFormInputTypesConfig[dynamicQuestion.inputType];
      if (config.fieldType === 'Address') {
        return defaultDynamicFormAddressArrayValue;
      }
      if (config.fieldType === 'ClaimEvent') {
        return defaultDynamicFormClaimEventArrayValue;
      }
      return {
        main: '',
      };
    };

    const renderFormComponent = (dynamicQuestion: DynamicQuestion, fieldName: string): JSX.Element => {
      const onBlur = () => {
        if (buttonClickedRef.current) {
          return false;
        }
        const hasErrors = !isEmpty(methods.formState.errors);
        if (dynamicQuestion.affectsOtherQuestions && !hasErrors) {
          submitForm(true).catch((e) => {
            if (!isErrorFromFormField(e)) {
              throw e;
            }
          });
        }
        return true;
      };
      if (dynamicQuestion.arrayElements) {
        return (
          <Stack key={dynamicQuestion.id}>
            <FormArrayFields
              key={fieldName}
              name={fieldName}
              defaultValue={getFormArrayDefault(dynamicQuestion)}
              FormArrayFieldComponent={DynamicFormArrayFields as FC<FormArrayFieldProps>}
              additionalProps={
                {
                  dynamicQuestion,
                  extractionValidationFields,
                  setExtractionValidationFields,
                  highlightValidationRequired,
                  onBlur,
                  resolvedSuggestion: resolvedAppliedSuggestions[fieldName],
                  dynamicFormDefaultValues,
                } as BaseDynamicFormArrayFieldsProps
              }
            />
          </Stack>
        );
      }

      const jsx = mapDynamicQuestionToFormComponent(
        dynamicQuestion,
        getDynamicValueFromFieldPath(formValues, fieldName),
        {
          name: fieldName,
          label: dynamicQuestion.fieldLabel,
        },
        dynamicFormDefaultValues,
        extractionValidationFields,
        setExtractionValidationFields,
        highlightValidationRequired,
        onBlur,
        undefined,
        resolvedAppliedSuggestions[fieldName],
      );
      const childJsx = dynamicQuestion.childValues?.map((childValue) =>
        renderFormComponent(childValue, `${fieldName}.children.${childValue.id}`),
      );

      return (
        <Fragment key={dynamicQuestion.id}>
          {jsx}
          {childJsx || null}
        </Fragment>
      );
    };

    const buildSectionWithQuestionAnsweredSubTitle = useCallback(
      (section: string) => {
        // this function is called also with activeSection that can still contain a section that has been removed, until it resets in useEffect
        // anyway it is a good convention to check if the section is still present in the answeredDynamicQuestionParametersBySections
        if (!answeredDynamicQuestionsBySections[section]) {
          return '';
        }
        const unanswered =
          answeredDynamicQuestionsBySections[section].total - answeredDynamicQuestionsBySections[section].answered;

        if (unanswered === 0) {
          return '';
        }

        return `${messages.general.unanswered}: ${unanswered}`;
      },
      [answeredDynamicQuestionsBySections],
    );

    const sliderItems = useMemo(
      () =>
        sectionList.map((item) => {
          // Leverage the buildSectionWithQuestionAnsweredSubTitle to infer if a section has required fields
          const sectionSubtitle = buildSectionWithQuestionAnsweredSubTitle(item);
          const sectionHasRequired = sectionSubtitle !== ''; // If subtitle is not empty, there are unanswered required fields

          return {
            label: item,
            subLabel: sectionSubtitle, // This displays the unanswered required fields count
            id: item,
            hasError: sectionsWithErrors.includes(item), // Mark as error if there are form validation errors
            hasRequired: sectionHasRequired, // Mark as required if there are unanswered required fields
            isCompleted:
              answeredDynamicQuestionsBySections[item]?.answered === answeredDynamicQuestionsBySections[item]?.total, // Mark as completed if all required fields are answered
          };
        }),
      [answeredDynamicQuestionsBySections, buildSectionWithQuestionAnsweredSubTitle, sectionList, sectionsWithErrors],
    );

    useImperativeHandle(ref, () => ({ submitForm })); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const getSubmitButtonText = () => {
      if (isBOR) {
        return isBOROnboarding ? messages.general.nextAddProducts : messages.buttons.update;
      }
      return messages.general.nextAddRiskAssessment;
    };

    return (
      <FormProvider {...methods}>
        <CoverageLinesChips
          heraldProducts={isHeraldSubmission ? heraldProducts : undefined}
          insuranceProducts={products}
          quoteExits={quoteExits}
          coverageLines={coverageLines!}
          setNextStep={setNextStep}
          sx={{
            px: 3,
            pt: 1,
          }}
        />
        <Stack overflow="hidden" height={1}>
          <FormWithStepper
            sectionProps={{
              activeSection,
              setActiveSection,
              sectionList: sliderItems,
            }}
            ref={scrollableDivRef}
            mainAreaDimensionsState={mainAreaDimensionsState}
            withPadding
          >
            {isSubmitting && <LoaderOverlay />}
            <Stack divider={<Divider sx={{ mt: 2, mb: 5 }} />}>
              {sectionList.map((section) => (
                <Stack
                  key={section}
                  ref={(el) => {
                    sectionRefs.current[section] = el as HTMLDivElement | null; // Type assertion for el
                  }} // Attach the ref for each section
                >
                  <Typography variant="subtitle2" mb={2}>
                    {section}
                  </Typography>
                  {dynamicQuestionsBySections[section].map((dynamicQuestion) =>
                    renderFormComponent(dynamicQuestion, dynamicQuestion.id),
                  )}
                </Stack>
              ))}
            </Stack>
            <SubmissionFooter
              isSubmitting={methods.formState.isSubmitting}
              onSubmit={async () => {
                try {
                  // if extractionValidationFields has any false value, it means that the user has not answered all the required fields
                  if (Object.values(extractionValidationFields).some((value) => !value)) {
                    scrollToFirstSuggestion();
                    highlightValidationRequiredOn();
                    return;
                  }
                  highlightValidationRequiredOff();
                  await submitForm();
                  onClose();
                } catch (e: any) {
                  if (!isErrorFromFormField(e)) {
                    throw e;
                  }
                }
              }}
              active={activeSection}
              setActiveSection={setActiveSection}
              list={sectionList}
              submitButtonText={getSubmitButtonText()}
              isAcknowledgmentFlow={isAcknowledgmentFlow}
            />
          </FormWithStepper>
          {showScrollButton && <ScrollFormIndicator handleScrollDown={handleScrollDown} />}
        </Stack>
      </FormProvider>
    );
  },
);

export default DynamicFormLayout;
