import { SubmissionAdditionalData } from '@common/types';
import { isArray, isEmpty, isPlainObject } from 'lodash';
import { Submission } from 'types';
import { DynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/enums';
import {
  DynamicFormState,
  DynamicQuestion,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { mapFormAddressToAddress } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/utils/address-utils';
import { flowQuestionsConfigMapper } from './flow-questions-config-mappers';

interface MapperProps {
  dynamicQuestionId: string;
  // dynamic question that is FormArrayField but in flow they are not array (we save the first one)
  onlyFirstElement?: boolean;
}

export function mapDynamicFormToPartialSubmission(
  dynamicFormState: DynamicFormState,
  dynamicQuestions: DynamicQuestion[],
): Partial<Submission> {
  const getMainValue = (dynamicQuestion: DynamicQuestion, main: any) => {
    if (dynamicQuestion.inputType === DynamicInputType.Address) {
      return isPlainObject(main) ? mapFormAddressToAddress(main) : undefined;
    }
    if (dynamicQuestion.inputType === DynamicInputType.Industry) {
      return isPlainObject(main)
        ? {
            heraldId: main.heraldId,
            description: main.description,
            naicsCode: main.naicsCode,
          }
        : undefined;
    }
    return main;
  };

  const getFirstFieldWithValue = (dynamicFormFields?: MapperProps[]) => {
    if (!dynamicFormFields) {
      return undefined;
    }
    let mappedValue: any;
    for (let i = 0; i < dynamicFormFields.length; i += 1) {
      const mappedFieldName = dynamicFormFields[i].dynamicQuestionId;
      const dynamicQuestion = dynamicQuestions.find(
        (flowStaticQuestionConfig) => flowStaticQuestionConfig.id === mappedFieldName,
      );

      // field appears in the form, get its value
      if (dynamicQuestion) {
        const dynamicFormStateEntry = dynamicFormState[mappedFieldName];
        if (isArray(dynamicFormStateEntry)) {
          mappedValue = dynamicFormStateEntry
            .map((field) => getMainValue(dynamicQuestion, field.main))
            // form array field with an empty row, we should filter it out by checking if it's not empty
            .filter(
              (field) =>
                !(
                  isEmpty(field) ||
                  // filter out objects that all keys are with empty values
                  (isPlainObject(field) && Object.values(field).every((value) => isEmpty(value)))
                ),
            );
          if (dynamicFormFields[i].onlyFirstElement && isArray(mappedValue)) {
            // eslint-disable-next-line prefer-destructuring
            mappedValue = mappedValue[0];
          }
        } else {
          mappedValue = getMainValue(dynamicQuestion, dynamicFormStateEntry?.main);
        }
      }

      if (mappedValue !== undefined) {
        break;
      }
    }

    return mappedValue;
  };

  let submissionData: Partial<Submission> = {};
  let submissionAdditionalData: Partial<SubmissionAdditionalData> = {};

  Object.entries(flowQuestionsConfigMapper).forEach(([flowQuestionId, mapperConfig]) => {
    const submissionConfig = mapperConfig.submission;

    const availableQuestions: MapperProps[] = [
      {
        dynamicQuestionId: flowQuestionId,
      },
      ...(mapperConfig.herald?.map((heraldQuestion) => ({
        dynamicQuestionId: heraldQuestion.id,
        onlyFirstElement: !!heraldQuestion.instance,
      })) || []),
    ];

    const mappedValue = getFirstFieldWithValue(availableQuestions);
    if (mappedValue !== undefined) {
      if (submissionConfig.source === 'submission') {
        submissionData = {
          ...submissionData,
          [submissionConfig.key]: mappedValue,
        };
      } else {
        submissionAdditionalData = {
          ...submissionAdditionalData,
          [submissionConfig.key]: mappedValue,
        };
      }
    }
  });

  return {
    ...submissionData,
    ...(!isEmpty(submissionData) && { additionalData: submissionAdditionalData }),
  };
}
