import {
  AddressDetails,
  FlowQuestionId,
  HeraldAddress,
  heraldMailingAddressQuestionIds,
  heraldPrimaryAddressQuestionIds,
} from '@common/types';

import { formatAddress } from '@common/utils';

export const flowStaticPrimaryAddressQuestionIds = [FlowQuestionId.PrimaryAddress];
export const primaryAddressQuestionIds = [...heraldPrimaryAddressQuestionIds, ...flowStaticPrimaryAddressQuestionIds];

export const flowStaticMailingAddressQuestionIds = [FlowQuestionId.MailingAddress];

export const mailingAddressQuestionIds = [...heraldMailingAddressQuestionIds, ...flowStaticMailingAddressQuestionIds];

export const allAddressQuestionIds = [...primaryAddressQuestionIds, ...mailingAddressQuestionIds];

export function buildDynamicFormAddressValue(addressDetails: AddressDetails): HeraldAddress {
  const { line1, line2, city, postalCode, state, country } = addressDetails;
  const mappedCountry = country === 'US' ? 'USA' : country;
  const result: HeraldAddress = {};
  if (line1) {
    result.line1 = line1;
  }
  if (line2) {
    result.line2 = line2;
  }
  if (city) {
    result.city = city;
  }
  if (postalCode) {
    result.postal_code = postalCode;
  }
  if (state) {
    result.state = state;
  }
  if (mappedCountry) {
    result.country_code = mappedCountry;
  }
  return result;
}

export const mapFormAddressToAddress = (addressFromValue?: HeraldAddress): AddressDetails => {
  if (!addressFromValue) {
    return { formattedAddress: '' };
  }
  const structuredAddress: Omit<AddressDetails, 'formattedAddress'> = {
    line1: addressFromValue.line1,
    line2: addressFromValue.line2,
    city: addressFromValue.city,
    postalCode: addressFromValue.postal_code,
    state: addressFromValue.state,
    country: addressFromValue.country_code,
  };
  return {
    ...structuredAddress,
    formattedAddress: formatAddress(addressFromValue),
  };
};
