import { normalizeExtractedDataSources } from '@common/submission-data/submission-extracted-data/utils';
import {
  ExtractedAddressValue,
  ExtractedAreaOfPractice,
  ExtractedAttribute,
  ExtractedData,
  FlowQuestionId,
  heraldQuestionId,
} from '@common/types';
import { isValidDomain } from '@common/utils';
import {
  getFirstEffectiveDateExtraction,
  normalizeAddressExtraction,
  normalizeAreaOfPracticeExtraction,
} from '@common/utils/extraction-utils';
import { isEqual, isUndefined, omitBy } from 'lodash';
import { useMemo } from 'react';
import { LegalEntity, PromptResultFeature, PromptResultFeatureType } from 'enums';
import { ExtractedDataFields, SuggestedValueProps } from 'hooks';
import { PartialSubmission, Suggestion } from 'types';
import { logger, parseTimezoneLessDate } from 'utils';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import { getValidDateSuggestion } from 'broker/utils';
import { DynamicQuestion, FormStateBaseProps } from './DynamicFormLayout/types';
import { flowQuestionsConfig } from './providers/flow/flow-questions/flow-questions-config';
import {
  extractValidMultipleNumericStringSuggestion,
  extractValidNumberSuggestion,
} from './suggestion-utils/number-suggestion-utils';
import { extractValidOptionsSuggestion } from './suggestion-utils/option-suggestion-utils';
import { buildSuggestedValueForArrayProps, buildSuggestedValueProps } from './suggestion-utils/suggestion-values-utils';

const FORM_FIELD_DATE_FORMAT = 'YYYY-MM-DD';
function extractValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidDateSuggestion(extractedAttribute);
  if (validSuggestion) {
    const suggestedValueProps = buildSuggestedValueProps(validSuggestion, promptResultFeature);
    return {
      ...suggestedValueProps,
      promptResultFeature,
      isEqualToFormValue: (suggestion: Suggestion, formValue?: FormStateBaseProps): boolean =>
        // Compare dates without timezones
        isEqual(suggestion.value, parseTimezoneLessDate(formValue?.main, FORM_FIELD_DATE_FORMAT)?.toISOString()),
    };
  }

  return undefined;
}

function extractValidAreaOfPractice(
  extractedAttribute: ExtractedAttribute<ExtractedAreaOfPractice[]>,
): SuggestedValueProps | undefined {
  const normalizedAreaOfPracticeExtraction = normalizeAreaOfPracticeExtraction(extractedAttribute);
  if (normalizedAreaOfPracticeExtraction) {
    return buildSuggestedValueForArrayProps(
      normalizedAreaOfPracticeExtraction,
      PromptResultFeature.SubmissionAreaOfPractice,
    );
  }
  return undefined;
}

function extractValidAddressSuggestion(
  extractedAttribute: ExtractedAttribute<ExtractedAddressValue>,
  type: keyof ExtractedAddressValue,
  isArray: boolean,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const normalizedAddressExtraction = normalizeAddressExtraction(extractedAttribute);
  const normalizedAddressTypeExtraction = normalizedAddressExtraction?.value?.[type];
  if (normalizedAddressExtraction && normalizedAddressTypeExtraction) {
    const suggestion = {
      reason: normalizedAddressExtraction.reason,
      citations: normalizedAddressExtraction.citations,
      value: normalizedAddressTypeExtraction,
    };

    return isArray
      ? buildSuggestedValueForArrayProps(
          {
            ...suggestion,
            value: [suggestion.value],
          },
          promptResultFeature,
        )
      : buildSuggestedValueProps(suggestion, promptResultFeature);
  }

  return undefined;
}

export function getFirstLimitExtraction(extractedData: ExtractedData) {
  return (
    extractedData.limitPL ||
    extractedData.limitGL ||
    extractedData.limitCyber ||
    extractedData.limitML ||
    extractedData.limitDO
  );
}

export function useGetExtractedData(
  dynamicQuestions: DynamicQuestion[],
  submission: PartialSubmission,
): ExtractedDataFields | undefined {
  return useMemo(() => {
    const normalizedExtractedData = submission.submissionExtractedData?.extractedData
      ? normalizeExtractedDataSources(submission.submissionExtractedData.extractedData)
      : undefined;
    // top level or additional values
    if (normalizedExtractedData) {
      try {
        const mailingAddressSuggestion = normalizedExtractedData.address
          ? extractValidAddressSuggestion(
              normalizedExtractedData.address,
              'mailing',
              false,
              PromptResultFeature.SubmissionApplicantMailingAddress,
            )
          : undefined;
        const getPrimaryAddressSuggestion = (promptResultFeature: PromptResultFeatureType, isArrayQuestion: boolean) =>
          normalizedExtractedData.address
            ? extractValidAddressSuggestion(
                normalizedExtractedData.address,
                'primary',
                isArrayQuestion,
                promptResultFeature,
              )
            : undefined;

        const industrySuggestion = normalizedExtractedData.industry?.value?.mappedIndexEntry?.[0]?.heraldId
          ? buildSuggestedValueProps(
              {
                value: convertIndustryObjectToOption({
                  heraldId: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].heraldId,
                  naicsCode: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].naicsCode,
                  description: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].indexEntryDescription,
                }),
                reason: normalizedExtractedData.industry.reason,
                citations: normalizedExtractedData.industry.citations,
              },
              PromptResultFeature.SubmissionIndustryClassification,
            )
          : undefined;

        const firstDueDateExtraction = getFirstEffectiveDateExtraction(normalizedExtractedData);

        const firstLimitExtraction = getFirstLimitExtraction(normalizedExtractedData);
        const isFlowQuestionHidden = (flowStaticQuestionId: FlowQuestionId) =>
          flowQuestionsConfig
            .find((flowStaticQuestionConfig) => flowStaticQuestionConfig.id === flowStaticQuestionId)
            ?.isHidden?.(dynamicQuestions, submission);

        const suggestedValueProps: Record<string, SuggestedValueProps | undefined> = {
          [heraldQuestionId.rsk_jb26_cyb_has_claims_history]: normalizedExtractedData.lossRunIndicatorCyber
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.lossRunIndicatorCyber,
                ['yes', 'no'],
                PromptResultFeature.SubmissionCyberLossRunIndicator,
              )
            : undefined,
          [heraldQuestionId.rsk_2aep_pl_has_claim_history]: normalizedExtractedData.lossRunIndicatorPL
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.lossRunIndicatorPL,
                ['yes', 'no'],
                PromptResultFeature.SubmissionPLLossRunIndicator,
              )
            : undefined,
          [heraldQuestionId.rsk_m4p9_insured_name]: normalizedExtractedData.insuredName
            ? buildSuggestedValueProps(normalizedExtractedData.insuredName, PromptResultFeature.SubmissionInsuredName)
            : undefined,
          [FlowQuestionId.InsuredName]: normalizedExtractedData.insuredName
            ? buildSuggestedValueProps(normalizedExtractedData.insuredName, PromptResultFeature.SubmissionInsuredName)
            : undefined,
          [heraldQuestionId.rsk_16rg_number_of_pte]: normalizedExtractedData.partTimeEmployees
            ? extractValidNumberSuggestion(
                normalizedExtractedData.partTimeEmployees,
                PromptResultFeature.SubmissionPartTimeEmployeesNumber,
              )
            : undefined,
          [FlowQuestionId.InsuredEmployeeCount]: normalizedExtractedData.partTimeEmployees
            ? extractValidNumberSuggestion(
                normalizedExtractedData.partTimeEmployees,
                PromptResultFeature.SubmissionPartTimeEmployeesNumber,
              )
            : undefined,
          [heraldQuestionId.rsk_0ie7_number_of_fte]: normalizedExtractedData.fullTimeEmployees
            ? extractValidNumberSuggestion(
                normalizedExtractedData.fullTimeEmployees,
                PromptResultFeature.SubmissionFullTimeEmployeesNumber,
              )
            : undefined,
          [heraldQuestionId.rsk_k39d_number_of_employees]: normalizedExtractedData.employeesNumber
            ? extractValidNumberSuggestion(
                normalizedExtractedData.employeesNumber,
                PromptResultFeature.SubmissionEmployeesNumber,
              )
            : undefined,
          [heraldQuestionId.rsk_a7he_total_payroll]: normalizedExtractedData.totalPayroll
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalPayroll,
                PromptResultFeature.SubmissionTotalPayroll,
              )
            : undefined,
          [heraldQuestionId.rsk_vrb1_total_annual_revenue]: normalizedExtractedData.totalAnnualRevenue
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalAnnualRevenue,
                PromptResultFeature.SubmissionTotalAnnualRevenue,
              )
            : undefined,
          [FlowQuestionId.InsuredAnnualRevenue]: normalizedExtractedData.totalAnnualRevenue
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalAnnualRevenue,
                PromptResultFeature.SubmissionTotalAnnualRevenue,
              )
            : undefined,
          [heraldQuestionId.rsk_cog2_total_assets]: normalizedExtractedData.totalAssets
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalAssets,
                PromptResultFeature.SubmissionTotalAssets,
              )
            : undefined,
          [heraldQuestionId.rsk_4b4x_years_of_operation]: normalizedExtractedData.yearsOfOperation
            ? extractValidNumberSuggestion(
                normalizedExtractedData.yearsOfOperation,
                PromptResultFeature.SubmissionYearsOfOperation,
              )
            : undefined,
          [heraldQuestionId.rsk_7ahp_has_domain]:
            normalizedExtractedData.applicationDomainName?.value &&
            isValidDomain(normalizedExtractedData.applicationDomainName.value)
              ? buildSuggestedValueProps(
                  {
                    value: 'yes',
                    reason: normalizedExtractedData.applicationDomainName.reason,
                    citations: normalizedExtractedData.applicationDomainName.citations,
                  },
                  PromptResultFeature.SubmissionHasDomain,
                )
              : undefined,
          [heraldQuestionId.rsk_dy7r_domain_names]:
            normalizedExtractedData.applicationDomainName?.value &&
            isValidDomain(normalizedExtractedData.applicationDomainName.value)
              ? buildSuggestedValueForArrayProps(
                  {
                    ...normalizedExtractedData.applicationDomainName,
                    value: [normalizedExtractedData.applicationDomainName.value],
                  },
                  PromptResultFeature.SubmissionApplicationDomainName,
                )
              : undefined,
          [FlowQuestionId.InsuredWebsite]:
            normalizedExtractedData.applicationDomainName?.value &&
            isValidDomain(normalizedExtractedData.applicationDomainName.value)
              ? buildSuggestedValueProps(
                  normalizedExtractedData.applicationDomainName,
                  PromptResultFeature.SubmissionApplicationDomainName,
                )
              : undefined,
          [heraldQuestionId.rsk_b3jm_2017_naics_index]: industrySuggestion,
          [FlowQuestionId.Industry]: industrySuggestion,
          [heraldQuestionId.rsk_837r_legal_entity]: normalizedExtractedData.legalEntity
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.legalEntity,
                Object.values(LegalEntity),
                PromptResultFeature.SubmissionLegalEntity,
              )
            : undefined,
          [heraldQuestionId.rsk_s7wq_corporate_structure]: normalizedExtractedData.organizationsCorporateStructure
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.organizationsCorporateStructure,
                ['Private', 'Not for profit'],
                PromptResultFeature.SubmissionOrganizationsCorporateStructure,
              )
            : undefined,
          [isFlowQuestionHidden(FlowQuestionId.PrimaryAddress)
            ? heraldQuestionId.rsk_jsy2_primary_address
            : FlowQuestionId.PrimaryAddress]: getPrimaryAddressSuggestion(
            PromptResultFeature.SubmissionApplicantPrimaryAddress,
            false,
          ),
          [heraldQuestionId.rsk_yor8_location]: getPrimaryAddressSuggestion(
            PromptResultFeature.SubmissionApplicantLocation,
            true,
          ),
          [isFlowQuestionHidden(FlowQuestionId.MailingAddress)
            ? heraldQuestionId.rsk_tvm3_mailing_address
            : FlowQuestionId.PrimaryAddress]: mailingAddressSuggestion,
          [heraldQuestionId.rsk_14kt_insured_contact_phone]: normalizedExtractedData.phoneNumber
            ? extractValidNumberSuggestion(
                normalizedExtractedData.phoneNumber,
                PromptResultFeature.SubmissionPhoneNumber,
              )
            : undefined,
          [FlowQuestionId.EffectiveDate]: firstDueDateExtraction
            ? extractValidDateSuggestion(firstDueDateExtraction, PromptResultFeature.SubmissionEffectiveDate)
            : undefined,
          [heraldQuestionId.cvg_m18u_pl_effective_date]: normalizedExtractedData.effectiveDatePL
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDatePL,
                PromptResultFeature.SubmissionPlEffectiveDate,
              )
            : undefined,
          [heraldQuestionId.cvg_48oo_gl_effective_date]: normalizedExtractedData.effectiveDateGL
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateGL,
                PromptResultFeature.SubmissionGlEffectiveDate,
              )
            : undefined,
          [heraldQuestionId.cvg_o3mw_cyb_effective_date]: normalizedExtractedData.effectiveDateCyber
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateCyber,
                PromptResultFeature.SubmissionCyberEffectiveDate,
              )
            : undefined,
          [heraldQuestionId.cvg_0em0_ml_effective_date]: normalizedExtractedData.effectiveDateML
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateML,
                PromptResultFeature.SubmissionMlEffectiveDate,
              )
            : undefined,
          [heraldQuestionId.rsk_t79b_insured_contact_name]: normalizedExtractedData.applicantContactName
            ? buildSuggestedValueProps(
                normalizedExtractedData.applicantContactName,
                PromptResultFeature.SubmissionDataApplicantContactName,
              )
            : undefined,
          [heraldQuestionId.rsk_5p6w_insured_contact_email]: normalizedExtractedData.applicantEmailAddress
            ? buildSuggestedValueProps(
                normalizedExtractedData.applicantEmailAddress,
                PromptResultFeature.SubmissionDataApplicantEmailAddress,
              )
            : undefined,
          [heraldQuestionId.rsk_voe4_cyb_security_officer]: normalizedExtractedData.contactAgreedToInformationSecurity
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.contactAgreedToInformationSecurity,
                ['yes', 'no'],
                PromptResultFeature.SubmissionContactAgreedToInformationSecurity,
              )
            : undefined,
          [heraldQuestionId.rsk_yo5p_anticipates_or_completed_public_offerings]:
            normalizedExtractedData.publiclyTradedSecurities
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.publiclyTradedSecurities,
                  ['In the past 13-24 months', 'In the past 12 months', 'In the next 12 months', 'None of the above'],
                  PromptResultFeature.SubmissionPubliclyTradedSecurities,
                )
              : undefined,
          [heraldQuestionId.rsk_sf46_anticipates_or_completed_private_placements]:
            normalizedExtractedData.privatePlacementInvestment
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.privatePlacementInvestment,
                  ['In the past 13-24 months', 'In the past 12 months', 'In the next 12 months', 'None of the above'],
                  PromptResultFeature.SubmissionPrivatePlacementInvestment,
                )
              : undefined,
          [heraldQuestionId.rsk_mur2_has_outside_individual_ownership]:
            normalizedExtractedData.shareholderBoardRepresentation
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.shareholderBoardRepresentation,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionShareholderBoardRepresentation,
                )
              : undefined,
          [heraldQuestionId.rsk_e718_has_breached_debt_covenants]: normalizedExtractedData.debtCovenantBreach
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.debtCovenantBreach,
                ['yes', 'no'],
                PromptResultFeature.SubmissionDebtCovenantBreach,
              )
            : undefined,
          [heraldQuestionId.rsk_3oiz_has_security_breach_requiring_notification]:
            normalizedExtractedData.securityBreachNotification
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.securityBreachNotification,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionSecurityBreachNotification,
                )
              : undefined,
          [heraldQuestionId.rsk_sw1p_has_pending_litigation]: normalizedExtractedData.pendingLitigation
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.pendingLitigation,
                ['yes', 'no'],
                PromptResultFeature.SubmissionPendingLitigation,
              )
            : undefined,
          [heraldQuestionId.rsk_p175_cyb_has_loss_in_unschedule_system_downtime]:
            normalizedExtractedData.unscheduledDowntimeLoss
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.unscheduledDowntimeLoss,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionUnscheduledDowntimeLoss,
                )
              : undefined,
          [heraldQuestionId.rsk_2i59_ownership_type]: normalizedExtractedData.ownershipType
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.ownershipType,
                ['Public', 'Private', 'Non-Profit', 'Public Sector', 'Partnership', 'Non-Corporates'],
                PromptResultFeature.SubmissionOwnershipType,
              )
            : undefined,
          [heraldQuestionId.rsk_5m1o_cyb_cloud_storage]: normalizedExtractedData.sensitiveCloudStorage
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.sensitiveCloudStorage,
                ['yes', 'no'],
                PromptResultFeature.SubmissionSensitiveCloudStorage,
              )
            : undefined,
          [heraldQuestionId.rsk_6ril_cyb_security_training]: normalizedExtractedData.infoSecTraining
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.infoSecTraining,
                ['yes', 'no'],
                PromptResultFeature.SubmissionInfoSecTraining,
              )
            : undefined,
          [heraldQuestionId.rsk_s9i6_is_franchise]: normalizedExtractedData.franchiseStatus
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.franchiseStatus,
                ['yes', 'no'],
                PromptResultFeature.SubmissionFranchiseStatus,
              )
            : undefined,
          [heraldQuestionId.rsk_2nge_is_business_broker]: normalizedExtractedData.businessBrokerServices
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.businessBrokerServices,
                ['yes', 'no'],
                PromptResultFeature.SubmissionBusinessBrokerServices,
              )
            : undefined,
          [heraldQuestionId.rsk_nbd2_is_investment_bank]: normalizedExtractedData.capitalRaisingServices
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.capitalRaisingServices,
                ['yes', 'no'],
                PromptResultFeature.SubmissionCapitalRaisingServices,
              )
            : undefined,
          [heraldQuestionId.rsk_2n3d_performs_merger_and_acquisition]: normalizedExtractedData.mergerAcquisitionServices
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.mergerAcquisitionServices,
                ['yes', 'no'],
                PromptResultFeature.SubmissionMergerAcquisitionServices,
              )
            : undefined,
          [heraldQuestionId.rsk_4hz4_backup_security_measure_type]: normalizedExtractedData.backupSecurityMeasures
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.backupSecurityMeasures,
                [
                  'MFA',
                  'Segmentation',
                  'Immutable',
                  'Virus/Malware scanning',
                  'Encryption',
                  'Test',
                  'Online or Designated Cloud Service',
                  'None of the above',
                ],
                PromptResultFeature.SubmissionBackupSecurityMeasures,
              )
            : undefined,
          [heraldQuestionId.rsk_9ljq_third_party_security_agreement]:
            normalizedExtractedData.thirdPartySecurityAgreements
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.thirdPartySecurityAgreements,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionThirdPartySecurityAgreements,
                )
              : undefined,
          [heraldQuestionId.rsk_bet9_tested_full_failover]: normalizedExtractedData.failoverTesting
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.failoverTesting,
                ['yes', 'no'],
                PromptResultFeature.SubmissionFailoverTesting,
              )
            : undefined,
          [heraldQuestionId.rsk_9ty9_has_dmz_separation]: normalizedExtractedData.systemSegregation
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.systemSegregation,
                ['yes', 'no'],
                PromptResultFeature.SubmissionSystemSegregation,
              )
            : undefined,
          [heraldQuestionId.rsk_rzc7_data_encryption_frequency]: normalizedExtractedData.dataEncryption
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.dataEncryption,
                ['yes', 'no'],
                PromptResultFeature.SubmissionEncryption,
              )
            : undefined,
          [heraldQuestionId.rsk_331u_patch_frequency]: normalizedExtractedData.updateFrequency
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.updateFrequency,
                ['Weekly or more', 'Monthly', 'Quarterly', 'Biannually', 'At least annually', 'None of the above'],
                PromptResultFeature.SubmissionUpdateFrequency,
              )
            : undefined,
          [heraldQuestionId.rsk_fzej_has_incident_response_plan]: normalizedExtractedData.incidentResponsePlan
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.incidentResponsePlan,
                ['yes', 'no'],
                PromptResultFeature.SubmissionIncidentResponsePlan,
              )
            : undefined,
          [heraldQuestionId.rsk_8s6e_cyb_training_implementation]:
            normalizedExtractedData.securityTrainingImplementation
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.securityTrainingImplementation,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionSecurityTrainingImplementation,
                )
              : undefined,
          [heraldQuestionId.rsk_na7l_perform_background_checks_employee]:
            normalizedExtractedData.newHireBackgroundChecks
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.newHireBackgroundChecks,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionNewHireBackgroundChecks,
                )
              : undefined,
          [heraldQuestionId.rsk_ms88_bank_account_reconciliation]: normalizedExtractedData.bankAccountReconciliation
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.bankAccountReconciliation,
                ['yes', 'no'],
                PromptResultFeature.SubmissionBankAccountReconciliation,
              )
            : undefined,
          [heraldQuestionId.rsk_0o4l_wire_transfer_authorization]: normalizedExtractedData.financialControlSegregation
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.financialControlSegregation,
                ['yes', 'no'],
                PromptResultFeature.SubmissionFinancialControlSegregation,
              )
            : undefined,
          [heraldQuestionId.rsk_77vv_vendor_verification_policy]:
            normalizedExtractedData.paymentInstructionVerificationPolicy
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.paymentInstructionVerificationPolicy,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionPaymentInstructionVerificationPolicy,
                )
              : undefined,
          [heraldQuestionId.rsk_u3u3_inventory_physical_count]: normalizedExtractedData.inventoryPhysicalCount
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.inventoryPhysicalCount,
                ['yes', 'no'],
                PromptResultFeature.SubmissionInventoryPhysicalCount,
              )
            : undefined,
          [heraldQuestionId.rsk_ut7e_high_value_exposure]: normalizedExtractedData.highValueInventoryExposure
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.highValueInventoryExposure,
                ['yes', 'no'],
                PromptResultFeature.SubmissionHighValueInventoryExposure,
              )
            : undefined,
          [heraldQuestionId.rsk_3h4w_counter_signature_required]:
            normalizedExtractedData.checkCountersignatureRequirement
              ? extractValidOptionsSuggestion(
                  normalizedExtractedData.checkCountersignatureRequirement,
                  ['yes', 'no'],
                  PromptResultFeature.SubmissionCheckCountersignatureRequirement,
                )
              : undefined,
          [heraldQuestionId.rsk_u8u8_locations_abroad]: normalizedExtractedData.domesticLocations
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.domesticLocations,
                ['yes', 'no'],
                PromptResultFeature.SubmissionDomesticLocations,
              )
            : undefined,
          [heraldQuestionId.rsk_ff4b_banking_transaction_control]: normalizedExtractedData.bankingTransactionSeparation
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.bankingTransactionSeparation,
                ['yes', 'no'],
                PromptResultFeature.SubmissionBankingTransactionSeparation,
              )
            : undefined,
          [heraldQuestionId.rsk_ize4_expects_debt_soon]: normalizedExtractedData.expectsDebtSoon
            ? buildSuggestedValueProps(
                normalizedExtractedData.expectsDebtSoon,
                PromptResultFeature.SubmissionExpectsDebtSoon,
              )
            : undefined,
          [heraldQuestionId.rsk_0bi6_net_income]: normalizedExtractedData.netIncome
            ? extractValidNumberSuggestion(normalizedExtractedData.netIncome, PromptResultFeature.SubmissionNetIncome)
            : undefined,
          [heraldQuestionId.rsk_ymb6_current_assets]: normalizedExtractedData.currentAssets
            ? extractValidNumberSuggestion(
                normalizedExtractedData.currentAssets,
                PromptResultFeature.SubmissionCurrentAssets,
              )
            : undefined,
          [heraldQuestionId.rsk_5s10_earnings_before_interest_taxes]:
            normalizedExtractedData.earningsBeforeInterestTaxes
              ? extractValidNumberSuggestion(
                  normalizedExtractedData.earningsBeforeInterestTaxes,
                  PromptResultFeature.SubmissionEarningsBeforeInterestTaxes,
                )
              : undefined,
          [heraldQuestionId.rsk_ff62_cash_or_cash_equivalent_assets]: normalizedExtractedData.cashOrCashEquivalentAssets
            ? extractValidNumberSuggestion(
                normalizedExtractedData.cashOrCashEquivalentAssets,
                PromptResultFeature.SubmissionCashOrCashEquivalentAssets,
              )
            : undefined,
          [heraldQuestionId.rsk_ep1m_cash_flow_from_operations]: normalizedExtractedData.cashFlowFromOperations
            ? extractValidNumberSuggestion(
                normalizedExtractedData.cashFlowFromOperations,
                PromptResultFeature.SubmissionCashFlowFromOperations,
              )
            : undefined,
          [heraldQuestionId.rsk_t0a4_current_liabilities]: normalizedExtractedData.currentLiabilities
            ? extractValidNumberSuggestion(
                normalizedExtractedData.currentLiabilities,
                PromptResultFeature.SubmissionCurrentLiabilities,
              )
            : undefined,
          [heraldQuestionId.rsk_5b5e_long_term_debt]: normalizedExtractedData.longTermDebt
            ? extractValidNumberSuggestion(
                normalizedExtractedData.longTermDebt,
                PromptResultFeature.SubmissionLongTermDebt,
              )
            : undefined,
          [heraldQuestionId.rsk_d6iz_amount_of_expected_debt]: normalizedExtractedData.amountOfExpectedDebt
            ? extractValidNumberSuggestion(
                normalizedExtractedData.amountOfExpectedDebt,
                PromptResultFeature.SubmissionAmountOfExpectedDebt,
              )
            : undefined,
          [heraldQuestionId.rsk_faf9_percentage_shares_held_by_board]:
            normalizedExtractedData.percentageSharesHeldByBoard
              ? extractValidNumberSuggestion(
                  normalizedExtractedData.percentageSharesHeldByBoard,
                  PromptResultFeature.SubmissionPercentageSharesHeldByBoard,
                )
              : undefined,
          [heraldQuestionId.rsk_gyj4_half_long_term_debt_matures]: normalizedExtractedData.halfLongTermDebtMatures
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.halfLongTermDebtMatures,
                ['yes', 'no'],
                PromptResultFeature.SubmissionHalfLongTermDebtMatures,
              )
            : undefined,
          [heraldQuestionId.rsk_phqr_total_liabilities]: normalizedExtractedData.totalLiabilities
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalLiabilities,
                PromptResultFeature.SubmissionTotalLiabilities,
              )
            : undefined,
          [heraldQuestionId.rsk_0k1o_total_entities_own_shares]: normalizedExtractedData.totalEntitiesOwnShares
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalEntitiesOwnShares,
                PromptResultFeature.SubmissionTotalEntitiesOwnShares,
              )
            : undefined,
          [heraldQuestionId.rsk_tu74_percentage_held_by_trusts]: normalizedExtractedData.percentageHeldByTrusts
            ? extractValidNumberSuggestion(
                normalizedExtractedData.percentageHeldByTrusts,
                PromptResultFeature.SubmissionPercentageHeldByTrusts,
              )
            : undefined,
          [heraldQuestionId.rsk_6onk_entity_type]: normalizedExtractedData.entityType
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.entityType,
                ['Independent', 'Parent', 'Subsidiary'],
                PromptResultFeature.SubmissionEntityType,
              )
            : undefined,
          [heraldQuestionId.rsk_9ux6_years_industry_management]: normalizedExtractedData.yearsIndustryManagement
            ? extractValidNumberSuggestion(
                normalizedExtractedData.yearsIndustryManagement,
                PromptResultFeature.SubmissionYearsIndustryManagement,
              )
            : undefined,
          [heraldQuestionId.rsk_tt0l_total_cash_exposure_outside]: normalizedExtractedData.totalCashExposureOutside
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalCashExposureOutside,
                PromptResultFeature.SubmissionTotalCashExposureOutside,
              )
            : undefined,
          [heraldQuestionId.rsk_tt8l_total_cash_exposure_inside]: normalizedExtractedData.totalCashExposureInside
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalCashExposureInside,
                PromptResultFeature.SubmissionTotalCashExposureInside,
              )
            : undefined,
          [heraldQuestionId.cvg_zlba_pl_aggregate_limit]: normalizedExtractedData?.limitPL
            ? extractValidNumberSuggestion(normalizedExtractedData.limitPL, PromptResultFeature.SubmissionLimitPL)
            : undefined,
          [heraldQuestionId.cvg_wsz8_gl_general_aggregate_limit]: normalizedExtractedData?.limitGL
            ? extractValidNumberSuggestion(normalizedExtractedData.limitGL, PromptResultFeature.SubmissionLimitGL)
            : undefined,
          [heraldQuestionId.cvg_agj9_cyb_aggregate_limit]: normalizedExtractedData?.limitCyber
            ? extractValidOptionsSuggestion(
                normalizedExtractedData.limitCyber,
                [250000, 500000, 1000000, 2000000, 3000000, 4000000, 5000000, 10000000],
                PromptResultFeature.SubmissionLimitPL,
              )
            : undefined,
          [heraldQuestionId.cvg_4k4d_ml_do_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_1x3s_ml_epli_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_w1n8_ml_fid_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_pl12_ml_kr_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_v1vv_ml_crm_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_pu6d_ml_do_retired_independent_liability_aggregate_limit]:
            normalizedExtractedData?.limitML
              ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
              : undefined,
          [heraldQuestionId.cvg_zfn3_ml_do_securityholder_derivative_demand_aggregate_limit]:
            normalizedExtractedData?.limitML
              ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
              : undefined,
          [heraldQuestionId.cvg_1tu1_ml_do_public_relations_expenses_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_bx1m_ml_do_terrorism_event_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_th04_ml_do_additional_clause_a_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,
          [heraldQuestionId.cvg_fk9q_ml_workplace_violence_aggregate_limit]: normalizedExtractedData?.limitML
            ? extractValidNumberSuggestion(normalizedExtractedData.limitML, PromptResultFeature.SubmissionLimitML)
            : undefined,

          [FlowQuestionId.Limit]: firstLimitExtraction
            ? extractValidNumberSuggestion(firstLimitExtraction, PromptResultFeature.SubmissionLimit)
            : undefined,

          [FlowQuestionId.NeedByDate]: normalizedExtractedData.needByDate
            ? extractValidDateSuggestion(normalizedExtractedData.needByDate, PromptResultFeature.SubmissionNeedByDate)
            : undefined,
          [FlowQuestionId.AttorneyNumber]: normalizedExtractedData.attorneyNumber
            ? extractValidNumberSuggestion(
                normalizedExtractedData.attorneyNumber,
                PromptResultFeature.SubmissionAttorneyNumber,
              )
            : undefined,
          [FlowQuestionId.AttorneyHireYears]: normalizedExtractedData?.attorneyHireYears
            ? extractValidMultipleNumericStringSuggestion(
                normalizedExtractedData.attorneyHireYears,
                PromptResultFeature.SubmissionAttorneyHireYears,
                true,
              )
            : undefined,
          [FlowQuestionId.AreasOfPractice]: normalizedExtractedData?.areaOfPractice
            ? extractValidAreaOfPractice(normalizedExtractedData.areaOfPractice)
            : undefined,
        };
        return omitBy(suggestedValueProps, isUndefined) as ExtractedDataFields;
      } catch (error) {
        logger.log('error', {
          message: 'useGetExtractedData: unexpected error, extracted data will not be used',
          error,
          extractedData: normalizedExtractedData,
        });
        return undefined;
      }
    }
    return undefined;
  }, [dynamicQuestions, submission]);
}
