import { SubmissionType } from '@common/enums/SubmissionType';
import { maxBy } from 'lodash';
import { SubmissionStatus } from 'enums';
import { PartialSubmission, Quote, Submission } from 'types';

export function isBorSubmission(partialSubmission: PartialSubmission) {
  return partialSubmission.type === SubmissionType.BOR;
}

export const isSubmissionLimitReached = (submission: Submission) =>
  !!submission.limit && submission.limit - submission.reachedLimit <= 0;

export const didQuotesReachLimit = (quotes: Quote[], limit: number) => {
  if (!quotes.length) {
    return false;
  }
  const topLayerQuote = maxBy(quotes, (q) => q.layer.attachmentPoint || 0);
  return (topLayerQuote?.limit || 0) + (topLayerQuote?.layer.attachmentPoint || 0) >= limit;
};

export function getIsHeraldSubmission(partialSubmission: PartialSubmission) {
  return !!(partialSubmission.heraldData && !partialSubmission.heraldData.isArchived);
}

export function showFastTrackIndication(partialSubmission: PartialSubmission) {
  return partialSubmission.status !== SubmissionStatus.Processing && partialSubmission.isFastTrack;
}
