import { groupBy, uniq } from 'lodash';
import { useMemo } from 'react';
import mapDynamicQuestionsToFormState from './mappers/map-dynamic-questions-to-form-state';
import { DynamicQuestion } from './types';

interface GetDynamicFormComputedDataProps {
  dynamicQuestions: DynamicQuestion[];
}

export function useGetDynamicFormComputedData({ dynamicQuestions }: GetDynamicFormComputedDataProps) {
  const defaultValues = useMemo(() => mapDynamicQuestionsToFormState(dynamicQuestions), [dynamicQuestions]);

  const dynamicQuestionsBySections: { [key in string]: DynamicQuestion[] } = useMemo(
    () => groupBy(dynamicQuestions, 'section'),
    [dynamicQuestions],
  );

  const sectionList = useMemo(() => uniq(dynamicQuestions.map((obj) => obj.section)), [dynamicQuestions]);

  return {
    defaultValues,
    dynamicQuestionsBySections,
    sectionList,
  };
}
