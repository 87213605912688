import { formatDateWithoutTime } from '@common/utils/date-utils';
import { isNil, toNumber } from 'lodash';
import { HeraldSchemaFormat } from 'clients/types';
import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { doesObjectContainNonEmptyValues } from './validations-utils';

export function removeEndingAfterUnderscore(str: string) {
  const lastIndex = str.lastIndexOf('_');
  if (lastIndex !== -1) {
    return str.substring(0, lastIndex);
  }
  return str; // No underscore found, return original string
}

export function normalizeDynamicFormValueAccordingToSchema(value: any, schema: DynamicQuestion['schema']) {
  // if user deleted all text field then we send null in order to reset the value
  if (value === '' || isNil(value)) {
    return null;
  }

  if (schema) {
    if (['integer', 'number'].includes(schema.type)) {
      return toNumber(value);
    }
    if (schema.format === HeraldSchemaFormat.Date) {
      return formatDateWithoutTime(value, 'YYYY-MM-DD');
    }

    if (schema.type === 'object') {
      return doesObjectContainNonEmptyValues(value)
        ? // normalizing properties values inside object
          Object.keys(value).reduce((acc, curr) => {
            // @ts-ignore
            acc[curr] = normalizeDynamicFormValueAccordingToSchema(value[curr], schema.properties![curr]);
            return acc;
          }, {})
        : // object is empty send null instead of object with empty properties
          null;
    }
  }

  return value;
}
