import { isValidDomain } from '@common/utils';
import { isString, keyBy, mapValues, omitBy } from 'lodash';
import * as yup from 'yup';
import { AnySchema } from 'yup';
import { HeraldSchema, HeraldSchemaFormat } from 'clients/types';
import { messages } from 'i18n';
import { customYupValidateNumber } from 'utils';

import { DynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/enums';
import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import {
  convertExactDigitsToReadableError,
  testRequiredNestedObject,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/utils/validations-utils';

const getSchemaForDynamicFormStringInputType = (dynamicSchema: HeraldSchema) => {
  let yupSchema;
  yupSchema = yup.string().nullable();

  // for string schema type
  if (dynamicSchema.min_length) {
    yupSchema = yupSchema.test(
      'MinLengthString',
      messages.errors.minChars(dynamicSchema.min_length),
      (value: string | null | undefined) =>
        typeof value === 'string' ? !value || value.length >= dynamicSchema.min_length! : true,
    );
  }

  // for string schema type
  if (dynamicSchema.max_length) {
    yupSchema = yupSchema.max(dynamicSchema.max_length, messages.errors.maxChars(dynamicSchema.max_length));
  }

  const dynamicFormSchemaPattern = dynamicSchema.pattern;
  if (dynamicFormSchemaPattern) {
    const message = isString(dynamicFormSchemaPattern) && convertExactDigitsToReadableError(dynamicFormSchemaPattern);
    yupSchema = yupSchema.test(
      'PatternTest',
      message || `Invalid Pattern ( ${dynamicFormSchemaPattern} )`,
      (value: string | null | undefined) => !value || new RegExp(dynamicFormSchemaPattern).test(value),
    );
  }
  if (dynamicSchema.format === HeraldSchemaFormat.Email) {
    yupSchema = yupSchema.email('Invalid Email');
  }

  if (dynamicSchema.format === HeraldSchemaFormat.Hostname) {
    yupSchema = yupSchema.test(
      'DomainValidation',
      'Invalid Domain',
      (value?: string | null) => !value || isValidDomain(value),
    );
  }

  return yupSchema;
};

const getSchemaForDynamicQuestion = (dynamicQuestionSchema: HeraldSchema, dynamicQuestion?: DynamicQuestion) => {
  let yupSchema;
  if (dynamicQuestion?.inputType === DynamicInputType.Industry) {
    // ignore industry schema we keep it as an object instead of string id, it doesn't need to go through validation as it is an autocomplete
    return yup.mixed().nullable();
  }
  if (dynamicQuestionSchema.type === 'string') {
    yupSchema = getSchemaForDynamicFormStringInputType(dynamicQuestionSchema);
  } else if (['integer', 'number'].includes(dynamicQuestionSchema.type)) {
    if (dynamicQuestionSchema.minimum || dynamicQuestionSchema.maximum) {
      yupSchema = yup
        .string()
        .default('')
        .test(
          'customYupValidateNumber',
          'customYupValidateNumber',
          customYupValidateNumber({
            fieldName: '',
            minNumber: dynamicQuestionSchema.minimum,
            maxNumber: dynamicQuestionSchema.maximum,
            allowZero: true,
          }),
        );
    }
  } else if (dynamicQuestionSchema.type === 'array') {
    if (dynamicQuestionSchema.min_items) {
      yupSchema = yup.array().min(dynamicQuestionSchema.min_items);
    }
  } else if (dynamicQuestionSchema.type === 'object') {
    const properties = dynamicQuestionSchema.properties!;
    yupSchema = yup
      .object()
      .shape(
        Object.keys(properties).reduce((acc, curr) => {
          // @ts-ignore
          acc[curr] = getSchemaForDynamicQuestion(properties[curr]);
          return acc;
        }, {}),
      )
      .test('at-least-one-field', 'at-least-one-field', testRequiredNestedObject(dynamicQuestionSchema.required || []));
  }

  return yupSchema;
};

export default function mapDynamicQuestionsToYupShape(dynamicQuestions: DynamicQuestion[]) {
  const schema: Record<string, AnySchema> = {};

  const getFormPropertySchema = (dynamicQuestion: DynamicQuestion) => {
    const mainSchema = dynamicQuestion.schema
      ? getSchemaForDynamicQuestion(dynamicQuestion.schema, dynamicQuestion)
      : undefined;
    const childValues = dynamicQuestion.arrayElements?.length
      ? dynamicQuestion.arrayElements[0].childValues
      : dynamicQuestion.childValues;
    const childrenSchema: any = childValues
      ? mapValues(keyBy(childValues, 'id'), (childValue) => getFormPropertySchema(childValue))
      : undefined;

    const childShape = childrenSchema
      ? yup
          .object()
          .shape(omitBy(childrenSchema, (schemaItem) => schemaItem === undefined) as Record<string, yup.Schema>)
      : undefined;

    let formPropertySchema;

    if (mainSchema || childShape) {
      formPropertySchema = yup.object().shape({
        main: mainSchema || yup.mixed().nullable(),
        children: childShape ? childShape.nullable() : yup.mixed().nullable(),
      });
      if (dynamicQuestion.arrayElements) {
        // @ts-ignore
        formPropertySchema = yup.array().of(formPropertySchema);
      }
    }
    return formPropertySchema;
  };

  dynamicQuestions.forEach((dynamicQuestion) => {
    schema[dynamicQuestion.id] = getFormPropertySchema(dynamicQuestion) || yup.mixed().nullable();
  });

  return yup.object().shape(schema);
}
