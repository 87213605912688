import { isArray } from 'lodash';
import { PartialSubmission } from 'types';
import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { isValueFilled } from 'broker/utils';
import { flowQuestionsConfig } from './flow-questions/flow-questions-config';

export function getFlowDynamicQuestions(
  heraldDynamicQuestions: DynamicQuestion[],
  submission: PartialSubmission,
): DynamicQuestion[] {
  return flowQuestionsConfig
    .filter((flowStaticQuestionConfig) => !flowStaticQuestionConfig.isHidden?.(heraldDynamicQuestions, submission))
    .map((config) => {
      let normalizedParameter = {
        ...config,
        value: config.getValue(submission),
      };
      // if isFormArrayField is true, then we need to create an array of dynamicQuestions where the value is an item from the value array
      if (config.isFormArrayField) {
        normalizedParameter = {
          ...normalizedParameter,
          arrayElements: isArray(normalizedParameter.value)
            ? normalizedParameter.value.map((val: any) => ({
                ...normalizedParameter,
                value: val,
              }))
            : [
                {
                  ...normalizedParameter,
                },
              ],
        };
      }
      return normalizedParameter;
    });
}

export function areAllFlowRequiredQuestionsFilled(submission: PartialSubmission) {
  return flowQuestionsConfig.every((questionConfig) => {
    const value = questionConfig.getValue(submission);
    return !questionConfig.requiredForCompletion || isValueFilled(value);
  });
}
