import { areHeraldProductsEligibleToCreateSubmission } from '@common/utils';
import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Paper, Stack, Typography } from '@common-components';
import { SubmissionMarketRequestStatus, SubmissionMethod } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { softShadow } from 'themes';
import { ExtendedMarketRequest } from 'types';
import { getIsHeraldSubmission } from 'utils';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { footerHeight } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { useOnSubmitMarketRequestsViaApi } from 'broker/pages/SubmissionWorkspacePage/utils';
import { isIncompleteApiApplication } from 'broker/utils';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import EmptyState from './components/EmptyState';
import Header from './components/Header';
import MarketRequestsList from './components/MarketRequestsList';
import SubmitViaApiDialog from './SubmitViaApiDialog';
import { BulkActionsMode } from './types';
import useBulkActionState from './useBulkActionState';
import { useBulkSubmitMethod } from './useBulkSubmitMethod';
import useMarketRequestsSortAndFilter from './useMarketRequestsSortAndFilter';
import useSelectionMarketRequests from './useSelectionMarketRequests';

interface MarketsProps {
  headerButtons?: ReactNode;
}

export default function MarketRequestsWorkspace({ headerButtons }: MarketsProps) {
  const { markets, activities, submission } = useSubmissionsWorkspace();
  const { bulkSubmitMethod, setBulkSubmitMethod, clearBulkSubmitMethod, bulkSubmitActionButtons } =
    useBulkSubmitMethod();
  const [comparisonExpiringPolicyFile, setComparisonExpiringPolicyFile] = useState<BoxItem | null>(null);
  const onCancel = useCallback(() => {
    setComparisonExpiringPolicyFile(null);
  }, []);

  const { showToast } = useToast();

  const { selectedProductsSubmitApi } = useUiStoreState();
  const { clearSelectedProductsSubmitApi } = useUiStoreActions();

  const { bulkActionButtons, setBulkActionMode, bulkActionMode } = useBulkActionState({
    bulkSubmitActions: bulkSubmitActionButtons,
    comparisonExpiringPolicyFileId: comparisonExpiringPolicyFile?.id,
    onCancel,
  });

  const MainScrollAreaId = 'marketsDataGridScrollArea';

  const extendedMarketRequests: ExtendedMarketRequest[] = useMemo(
    () => submissionMarketsToExtendedMarketRequests(markets, submission),
    [markets, submission],
  );

  const onFilterChange = () => {
    clearBulkSubmitMethod();
  };

  const sortingProps = useMarketRequestsSortAndFilter(extendedMarketRequests, onFilterChange);

  const selectedFilter = sortingProps.filters.find((filter) => filter.checked)?.key;

  const onSubmitMarketRequestsViaApi = useOnSubmitMarketRequestsViaApi();

  const apiProducts = useMemo(() => {
    if (submission) {
      return extendedMarketRequests.filter(
        (marketRequest) =>
          selectedProductsSubmitApi.some(
            (product) =>
              product.id === marketRequest.insuranceProductId &&
              product.externalProductId &&
              areHeraldProductsEligibleToCreateSubmission([product.externalProductId], submission.heraldData).eligible,
          ) && marketRequest.status === SubmissionMarketRequestStatus.ReadyToMarket,
      );
    }

    return [];
  }, [extendedMarketRequests, selectedProductsSubmitApi, submission]);

  const submitViaApiPopupIsOpen = useMemo(
    () =>
      !isEmpty(selectedProductsSubmitApi) &&
      !!submission &&
      getIsHeraldSubmission(submission) &&
      !isIncompleteApiApplication(submission),
    [selectedProductsSubmitApi, submission],
  );

  const [isSubmittingViaApi, setIsSubmittingViaApi] = useState(false);

  const { selectedMarketRequests, onMarketRequestSelected, clearSelections } = useSelectionMarketRequests({
    marketRequests: extendedMarketRequests,
    selectedFilter,
    multipleSelection: true,
  });

  function clearSearchAndFilter() {
    sortingProps.setSearch('');
    sortingProps.setAllStatusSelected();
  }

  function getSelectedMarketRequests() {
    return Object.keys(selectedMarketRequests)
      .filter((marketRequestId) => selectedMarketRequests[marketRequestId].selected)
      .map((marketRequestId) => selectedMarketRequests[marketRequestId].marketRequest);
  }

  const onSelectSubmissionMethod = (method: SubmissionMethod) => {
    clearSelections();
    setBulkSubmitMethod(method);
    setBulkActionMode(BulkActionsMode.Submit);
  };

  const onSelectionButtonClick = () => {
    clearSelections();
  };

  const onSelectQuoteComparison = (expiringPolicyFile?: BoxItem) => {
    clearSelections();
    if (expiringPolicyFile) {
      setComparisonExpiringPolicyFile(expiringPolicyFile);
    }
    setBulkActionMode(BulkActionsMode.CompareQuotes);
  };

  const footerText = isEmpty(comparisonExpiringPolicyFile)
    ? messages.submissionWorkspace.bulkActionsFooter.getSelectedText(getSelectedMarketRequests().length)
    : messages.submissionWorkspace.bulkActionsFooter.getSelectedTextWithPreSelectedFileName(
        getSelectedMarketRequests().length,
        comparisonExpiringPolicyFile!.name,
      );

  return (
    <>
      <Header
        sortingProps={sortingProps}
        headerButtons={headerButtons}
        showSearchAndFilter={!isEmpty(markets)}
        onSelectSubmissionMethod={onSelectSubmissionMethod}
        onSelectQuoteComparison={onSelectQuoteComparison}
      />
      <Stack component={Paper} bgcolor="grey.50" borderRadius={0} variant="outlined" overflow="hidden" flex="1 1 0">
        <MainScrollArea
          id={MainScrollAreaId}
          sx={{
            overflow: 'auto',
            p: 2,
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isEmpty(sortingProps.sortedAndFilteredItems) || isEmpty(extendedMarketRequests) ? (
            <EmptyState emptyState={isEmpty(extendedMarketRequests)} clearFilters={clearSearchAndFilter} />
          ) : (
            <MarketRequestsList
              selectedMarketRequests={selectedMarketRequests}
              showSelectionCheck={bulkActionButtons.length > 0}
              multipleSelection
              onMarketRequestSelected={onMarketRequestSelected}
              marketRequests={sortingProps.sortedAndFilteredItems}
              searchWord={sortingProps.search}
              activities={activities}
              selectedSubmitMethod={bulkSubmitMethod}
              bulkActionMode={bulkActionMode}
            />
          )}
        </MainScrollArea>
      </Stack>
      {bulkActionButtons.length > 0 && sortingProps.sortedAndFilteredItems.length > 0 && (
        <Stack
          borderTop={1}
          px={2}
          gap={1}
          borderColor="grey.300"
          component={Paper}
          boxShadow={softShadow}
          height={footerHeight}
          width={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" overflow="hidden">
            <Typography variant="body2" noWrap>
              {footerText}
            </Typography>
          </Stack>
          <Stack direction="row-reverse" gap={1}>
            {bulkActionButtons.map((bulkActionButton, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {bulkActionButton({
                  selectedMarketRequests: getSelectedMarketRequests(),
                  onSelectionButtonClick,
                })}
              </Fragment>
            ))}
          </Stack>
        </Stack>
      )}
      <SubmitViaApiDialog
        numberOfProducts={apiProducts.length}
        isSubmitting={isSubmittingViaApi}
        isOpen={!!submitViaApiPopupIsOpen}
        onClose={() => {
          clearSelectedProductsSubmitApi();
        }}
        onConfirm={async () => {
          if (submission) {
            try {
              setIsSubmittingViaApi(true);
              await onSubmitMarketRequestsViaApi(submission, apiProducts);
              setIsSubmittingViaApi(false);
              showToast('success', { message: messages.heraldApi.rfqSentViaApi });
              clearSelectedProductsSubmitApi();
            } catch (e) {
              setIsSubmittingViaApi(false);
              throw e;
            }
          }
        }}
      />
    </>
  );
}
