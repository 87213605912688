import { getCoverageLineConfig } from '@common/config';
import * as commonEmailVariables from '@common/email-variables';
import {
  CommonTemplateVariablesResolver,
  EmailTemplateVariables,
  TemplateVariablesResolver,
} from '@common/email-variables';
import { flatten, maxBy, pick, uniq } from 'lodash';
import { EmailTemplateType, isMarketOrganization, SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';
import { Layer, Quote, SubmissionMarket } from 'types';
import { attachmentPointText, formatBigNumber, getDisplayDate, getIsHeraldSubmission, itemsToHtmlList } from 'utils';
import getHtmlTable from 'utils/markdownTable';
import { generateSubmissionPath } from 'broker/broker-routes';
import { EmailTemplateContext, EmailType } from 'broker/components/EmailEditor/store/types';
import { getDeclinationMessage } from 'broker/configuration-mappers/declination-config';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';
// The below eslint-disable is due to the fact that the email editor highly depends on the submission workspace page,
// but some parts of it might be relevant to use elsewhere. This should be addressed in CAP-3264.
// eslint-disable-next-line import/no-restricted-paths
import { getConceptualTowerRowsData } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/utils';
// eslint-disable-next-line import/no-restricted-paths
import { getMarketReportsRows } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import {
  getActiveQuotes,
  getStaticSubmissionMissingOptionalFields,
  getStaticSubmissionMissingRequiredFields,
  getSubjectivitiesFromQuotes,
} from 'broker/utils/index';
import { commonResolver } from './common-resolver';
import { getQuote } from './quote-variable';

export interface VariableInfo extends commonEmailVariables.VariableInfo {
  unresolvedInEditForAll?: boolean;
}

function getAttachmentPoint({ layer, recipient, submissionMarkets }: EmailTemplateContext) {
  // email is in a context of single layer - returning its attachment point
  if (layer) {
    return attachmentPointText(layer.attachmentPoint);
  }

  const recipientMarket = submissionMarkets.find((market) => market.userMarketId === recipient?.id);

  const recipientQuotes = recipientMarket?.activeQuotes;

  if (recipientQuotes && recipientQuotes.length > 0) {
    const attachmentPointsText = uniq(
      recipientQuotes.map((quoteItem) => attachmentPointText(quoteItem.layer!.attachmentPoint)),
    );
    return attachmentPointsText.join(' & ');
  }

  return undefined;
}

const declinationMessage = (triggeringMarket?: SubmissionMarket) => {
  const declinedMarketRequests = triggeringMarket?.marketRequests.filter(
    (marketRequest) => marketRequest.status === SubmissionMarketRequestStatus.Declined,
  );

  const latestDeclinationRequest = maxBy(declinedMarketRequests, ({ updatedAt }) => new Date(updatedAt).getTime());

  if (!latestDeclinationRequest) {
    return undefined;
  }
  return getDeclinationMessage(latestDeclinationRequest.declination?.declinationReasons ?? []);
};

const conceptualTowerHtmlTable = (quotes: Quote[], layers: Layer[]) => {
  const headers = {
    id: 'header',
    layer: messages.ConceptualTowerPage.columnHeaders.layer,
    status: messages.ConceptualTowerPage.columnHeaders.status,
    limit: messages.ConceptualTowerPage.columnHeaders.limit,
    marketName: messages.ConceptualTowerPage.columnHeaders.marketName,
    isAdmitted: messages.ConceptualTowerPage.columnHeaders.admitted,
    premium: messages.ConceptualTowerPage.columnHeaders.premium,
    rpm: messages.ConceptualTowerPage.columnHeaders.rpm,
  };
  return getHtmlTable(getConceptualTowerRowsData(quotes ?? [], layers ?? []).layersForReport, headers);
};

const marketingReportHtmlTable = (submissionMarkets: SubmissionMarket[]) => {
  const headers = {
    id: 'header',
    marketName: messages.marketingReportPage.columnHeaders.marketName,
    status: messages.marketingReportPage.columnHeaders.status,
    coverageLines: messages.marketingReportPage.columnHeaders.coverageLines,
    additionalInfo: messages.marketingReportPage.columnHeaders.additionalInfo,
  };
  return getHtmlTable(getMarketReportsRows(submissionMarkets, false), headers);
};

const informationRequestedReportHtmlTable = (submissionMarkets: SubmissionMarket[]) => {
  const headers = {
    id: 'header',
    marketName: messages.marketingReportPage.columnHeaders.marketName,
    coverageLines: messages.marketingReportPage.columnHeaders.coverageLines,
    additionalInfo: messages.marketingReportPage.columnHeaders.additionalInfo,
  };
  return getHtmlTable(getMarketReportsRows(submissionMarkets, true), headers);
};

const expiringTowerHtmlTable = (submissionMarkets: SubmissionMarket[]) => {
  const headers = {
    id: 'header',
    marketName: messages.expiringTowerPage.columnHeaders.marketName,
    layer: messages.expiringTowerPage.columnHeaders.layer,
    premium: messages.expiringTowerPage.columnHeaders.premium,
    aggregateLimit: messages.expiringTowerPage.columnHeaders.aggregateLimit,
    attachmentPoint: messages.expiringTowerPage.columnHeaders.AttachmentPointRetention,
  };

  const incumbentData = flatten(
    submissionMarkets.map(
      (market) =>
        market.incumbentInfo.map((incumbent) => ({
          ...incumbent,
          marketName: market.marketName,
          marketId: market.userMarketId,
          layer: incumbent.isPrimary ? messages.general.primary : messages.general.excess,
        })) || [],
    ),
  );
  return getHtmlTable(incumbentData.reverse(), headers);
};

const templateVariablesConfig: Record<string, { supportedTemplate: EmailTemplateType[] }> = {
  [EmailTemplateVariables.marketingStepLink]: {
    supportedTemplate: [EmailTemplateType.UserNotification],
  },
  [EmailTemplateVariables.userName]: {
    supportedTemplate: [EmailTemplateType.UserNotification],
  },
};

const EmailTemplateVariablesMap: TemplateVariablesResolver<EmailTemplateContext, VariableInfo> = {
  ...commonResolver,
  [EmailTemplateVariables.targetLimit]: ({ submission }: EmailTemplateContext) => ({
    value: submission.limit ? `$${formatBigNumber(submission.limit)}` : undefined,
  }),
  [EmailTemplateVariables.triggeringMarket]: ({ triggeringMarket }: EmailTemplateContext) => ({
    value: triggeringMarket?.marketName,
  }),
  [EmailTemplateVariables.attachmentPoint]: (context: EmailTemplateContext) => ({
    value: getAttachmentPoint(context),
  }),
  [EmailTemplateVariables.marketRecipient]: ({ recipient }: EmailTemplateContext) => ({
    value: recipient?.type && isMarketOrganization(recipient.type) ? recipient.name : undefined,
  }),
  [EmailTemplateVariables.declinationReason]: ({ triggeringMarket }: EmailTemplateContext) => ({
    value: triggeringMarket ? `<li>${declinationMessage(triggeringMarket)}</li>` : undefined,
  }),
  [EmailTemplateVariables.signature]: (context: EmailTemplateContext) => {
    const baseSignatureResolver = CommonTemplateVariablesResolver[EmailTemplateVariables.signature];

    const { user, selectedTemplate } = context;
    const isBackofficeTemplate = selectedTemplate && EmailTemplateConfig[selectedTemplate].backOfficeTemplate;

    if (!user.endUser || isBackofficeTemplate) {
      return baseSignatureResolver(context);
    }

    return baseSignatureResolver({ ...context, user: user.endUser });
  },
  [EmailTemplateVariables.conceptualTower]: ({ quotes, layers }: EmailTemplateContext) => ({
    value: conceptualTowerHtmlTable(quotes ?? [], layers ?? []).toString(),
  }),
  [EmailTemplateVariables.marketingReport]: ({ submissionMarkets }: EmailTemplateContext) => ({
    value: marketingReportHtmlTable(submissionMarkets),
  }),
  [EmailTemplateVariables.informationRequested]: ({ submissionMarkets }: EmailTemplateContext) => ({
    value: informationRequestedReportHtmlTable(submissionMarkets),
  }),
  [EmailTemplateVariables.emailAttachments]: ({ attachedFiles }: EmailTemplateContext) => ({
    value:
      attachedFiles && attachedFiles.length > 0
        ? itemsToHtmlList(
            attachedFiles.map((file) => file.name),
            messages.emailEditor.emailAttachmentsVariable,
          )
        : undefined,
    unresolvedInEditForAll: true,
  }),
  [EmailTemplateVariables.userName]: ({ user }: EmailTemplateContext) => ({
    value: user.endUser?.firstName ?? '',
  }),
  [EmailTemplateVariables.marketingStepLink]: ({ submission }: EmailTemplateContext) => ({
    value: submission.id
      ? `<a href="${`${window.location.protocol}//${window.location.host}${generateSubmissionPath(
          submission.id,
          'marketing',
        )}`}" >${messages.general.here}</a>`
      : undefined,
  }),
  [EmailTemplateVariables.companyLetterhead]: ({ user }: EmailTemplateContext) => ({
    value: user.organization.letterhead ?? '',
  }),
  [EmailTemplateVariables.brokerageName]: ({ user }: EmailTemplateContext) => ({
    value: user.organization.name ?? '',
  }),
  [EmailTemplateVariables.missingFiles]: ({ submissionMissingFiles }: EmailTemplateContext) => ({
    value: submissionMissingFiles?.length
      ? itemsToHtmlList(submissionMissingFiles, messages.templateVariables.missingInformationRequest.missingFilesTitle)
      : undefined,
  }),
  [EmailTemplateVariables.missingSubmissionDetails]: ({ submission }: EmailTemplateContext) => {
    const missingSubmissionFields = getIsHeraldSubmission(submission)
      ? // todo: herald task CAP-3515
        []
      : getStaticSubmissionMissingRequiredFields(submission).concat(
          getStaticSubmissionMissingOptionalFields(submission),
        );
    const missingFieldsNames = missingSubmissionFields.map((config) => config.label);
    return {
      value: missingFieldsNames.length
        ? itemsToHtmlList(
            missingFieldsNames,
            messages.templateVariables.missingInformationRequest.missingSubmissionDetailsTitle,
          )
        : undefined,
    };
  },
  [EmailTemplateVariables.quote]: ({ triggeringQuote }: EmailTemplateContext) => ({
    value: triggeringQuote ? getQuote(triggeringQuote) : undefined,
  }),
  [EmailTemplateVariables.quoteMarketName]: ({ triggeringQuote }: EmailTemplateContext) => ({
    value: triggeringQuote?.marketName,
  }),
  [EmailTemplateVariables.quoteAggregateLimit]: ({ triggeringQuote }: EmailTemplateContext) => ({
    value: triggeringQuote?.limit ? `$${formatBigNumber(triggeringQuote.limit)}` : undefined,
  }),
  [EmailTemplateVariables.quoteCoverageLines]: ({ triggeringQuote }: EmailTemplateContext) => ({
    value: triggeringQuote?.coverageLines
      ? triggeringQuote.coverageLines.map((coverageLine) => getCoverageLineConfig(coverageLine).text).join(', ')
      : undefined,
  }),
  [EmailTemplateVariables.quoteDate]: ({ triggeringQuote }: EmailTemplateContext) => ({
    value: triggeringQuote?.updatedAt && getDisplayDate(triggeringQuote.updatedAt),
  }),
  [EmailTemplateVariables.numberOfMarkets]: ({ submissionMarkets }: EmailTemplateContext) => ({
    value: submissionMarkets.length.toString(),
  }),
  [EmailTemplateVariables.numberOfDeclinations]: ({ extendedMarketRequests }: EmailTemplateContext) => ({
    value: extendedMarketRequests
      .filter((extendedMarketRequest) => extendedMarketRequest.status === SubmissionMarketRequestStatus.Declined)
      .length.toString(),
  }),
  [EmailTemplateVariables.numberOfQuotes]: ({ quotes }: EmailTemplateContext) => ({
    value: getActiveQuotes(quotes).length.toString(),
  }),
  [EmailTemplateVariables.subjectivities]: ({ extendedMarketRequests, attachedFiles }: EmailTemplateContext) => ({
    value: attachedFiles ? getSubjectivitiesFromQuotes(extendedMarketRequests, attachedFiles) : '',
  }),
};

const ProposalAutoResolvedVariables = pick(EmailTemplateVariablesMap, [
  EmailTemplateVariables.signature,
  EmailTemplateVariables.companyLetterhead,
]);

const defaultTemplateVariables = [
  EmailTemplateVariables.coverageLines,
  EmailTemplateVariables.targetLimit,
  EmailTemplateVariables.effectiveDate,
  EmailTemplateVariables.needByDate,
  EmailTemplateVariables.brokerageName,
  EmailTemplateVariables.signature,
  EmailTemplateVariables.companyLetterhead,
  EmailTemplateVariables.conceptualTower,
  EmailTemplateVariables.emailAttachments,
];

const customerTemplateVariables = [EmailTemplateVariables.customerFirstNames, EmailTemplateVariables.marketingReport];

const marketingTemplateVariables = [
  EmailTemplateVariables.insuredName,
  EmailTemplateVariables.insuredAddress,
  EmailTemplateVariables.insuredEmployeeCount,
  EmailTemplateVariables.insuredWebsite,
  EmailTemplateVariables.marketRecipientFirstNames,
  EmailTemplateVariables.marketRecipient,
];

const getRecipientBasedVariables = (emailType: EmailType) => {
  if (emailType === EmailType.Market) {
    return [...marketingTemplateVariables, ...defaultTemplateVariables];
  }

  if (emailType === EmailType.Retailer) {
    return [...customerTemplateVariables, ...defaultTemplateVariables];
  }

  if (emailType === EmailType.UserNotification) {
    return [...customerTemplateVariables, ...marketingTemplateVariables, ...defaultTemplateVariables];
  }

  return defaultTemplateVariables;
};
export {
  EmailTemplateVariablesMap,
  getRecipientBasedVariables,
  templateVariablesConfig,
  ProposalAutoResolvedVariables,
  marketingReportHtmlTable,
  conceptualTowerHtmlTable,
  expiringTowerHtmlTable,
  informationRequestedReportHtmlTable,
};
