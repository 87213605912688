import { isEmpty } from 'lodash';
import { TestContext } from 'yup';
import { messages } from 'i18n';
import { AnyObject } from 'types';
import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

export function doesObjectContainNonEmptyValues(object: AnyObject) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(object).some(([_key, val]) => !isEmpty(val));
}

export function testRequiredNestedObject(requiredFields: string[]) {
  return (value: AnyObject, context: TestContext) => {
    const { path, createError } = context;

    if (doesObjectContainNonEmptyValues(value)) {
      const fields = Object.keys(value);
      for (const field of fields) {
        if (requiredFields.includes(field) && isEmpty(value[field])) {
          return createError({
            path: `${path}.${field}`, // Specify the path for each specific field
            message: 'Required',
          });
        }
      }
    }

    return true;
  };
}

// ['risk_values',index] or ['coverage_values',index]
export function parseUpdateErrorInvalidFormatItem(str: string) {
  // Regular expression pattern to match "propertyName[index]"
  const regex = /(\w+)\[(\d+)\]/;

  // Match the string against the regular expression
  const match = str.match(regex);

  // If there's a match, return an array with property name and index
  if (match) {
    const [, propertyName, indexStr] = match;
    const index = parseInt(indexStr, 10);
    return [propertyName, index];
  }

  // If no match, return null or handle as needed
  return null;
}

export function isDynamicQuestionParameterNotAnswered(dynamicQuestionNormalizedParameter: DynamicQuestion): boolean {
  if (dynamicQuestionNormalizedParameter.arrayElements?.length) {
    return dynamicQuestionNormalizedParameter.arrayElements.some((element) =>
      isDynamicQuestionParameterNotAnswered(element),
    );
  }

  if (dynamicQuestionNormalizedParameter.childValues?.length) {
    return dynamicQuestionNormalizedParameter.childValues.some((element) =>
      isDynamicQuestionParameterNotAnswered(element),
    );
  }

  // if dynamicQuestionNormalizedParameter is optional then it is ok even of not answered
  return !dynamicQuestionNormalizedParameter.requiredForCompletion || dynamicQuestionNormalizedParameter.value !== null;
}

export const convertExactDigitsToReadableError = (pattern: string) => {
  const match = pattern.match(/^\^\\d\{(\d+)\}\$$/);
  if (match) {
    return messages.errors.patternTestExactDigits(match[1]);
  }
  return '';
};
