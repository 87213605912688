import { HeraldSchema } from 'clients/types';
import { StateCode } from 'enums';
import {
  DynamicFormState,
  DynamicQuestion,
  FormStateBaseProps,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { normalizeDynamicFormValueAccordingToSchema } from './mapping-utils';

export const DynamicFormAddressSchema: HeraldSchema = {
  title: '',
  type: 'object',
  properties: {
    line1: {
      type: 'string',
      title: 'Line 1',
      min_length: 1,
      max_length: 46,
    },
    line2: {
      type: 'string',
      title: 'Line 2',
      min_length: 0,
      max_length: 46,
    },
    line3: {
      type: 'string',
      title: 'Line 3',
      min_length: 0,
      max_length: 46,
    },
    city: {
      type: 'string',
      title: 'City',
      min_length: 1,
      max_length: 50,
    },
    state: {
      type: 'string',
      enum: Object.values(StateCode),
      title: 'State',
    },
    postal_code: {
      type: 'string',
      pattern: '^\\d{5}$',
      title: 'Postal code',
    },
    country_code: {
      type: 'string',
      enum: ['USA'] as string[],
      title: 'Country',
    },
  },
  required: ['line1', 'city', 'state', 'postal_code', 'country_code'] as string[],
} as const;

export function getNormalizeDynamicFormStateAccordingToSchema(
  dynamicFormState: DynamicFormState,
  dynamicQuestions: DynamicQuestion[],
): DynamicFormState {
  const normalizedDynamicFormState: DynamicFormState = {};

  // Overloaded signatures
  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps,
    normalizedParameter: DynamicQuestion,
  ): FormStateBaseProps;

  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps[],
    normalizedParameter: DynamicQuestion,
  ): FormStateBaseProps[];

  // Implementation
  function getNormalizedValueFromFormStateBaseProps(
    formStateBaseProps: FormStateBaseProps | FormStateBaseProps[],
    normalizedParameter: DynamicQuestion,
  ): FormStateBaseProps | FormStateBaseProps[] {
    if (Array.isArray(formStateBaseProps)) {
      return formStateBaseProps.map((formStateItem) =>
        getNormalizedValueFromFormStateBaseProps(formStateItem, normalizedParameter),
      );
    }

    return {
      ...formStateBaseProps,
      main: normalizeDynamicFormValueAccordingToSchema(formStateBaseProps.main, normalizedParameter.schema),
      children: formStateBaseProps.children
        ? Object.keys(formStateBaseProps.children).reduce((acc, childParameterId) => {
            const val = formStateBaseProps.children![childParameterId];
            const childNormalizedParameter = normalizedParameter?.arrayElements
              ?.flatMap((arrayElement) =>
                arrayElement.childValues?.find((childValue) => childValue.id === childParameterId),
              )
              .find(Boolean);

            // Ensure childNormalizedParameter is not undefined before calling the function
            if (childNormalizedParameter) {
              // Check if 'val' is an array or a single object
              if (Array.isArray(val)) {
                acc[childParameterId] = getNormalizedValueFromFormStateBaseProps(val, childNormalizedParameter);
              } else if (val) {
                acc[childParameterId] = getNormalizedValueFromFormStateBaseProps(val, childNormalizedParameter);
              } else {
                acc[childParameterId] = undefined;
              }
            } else {
              acc[childParameterId] = undefined;
            }

            return acc;
          }, {} as Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>)
        : undefined,
    };
  }

  // Normalize the form state
  Object.keys(dynamicFormState).forEach((parameterId) => {
    const normalizedParameter = dynamicQuestions.find((param) => param.id === parameterId);

    if (normalizedParameter) {
      const formValue = dynamicFormState[parameterId];

      if (formValue) {
        // Use type guard to call the correct overload
        if (Array.isArray(formValue)) {
          normalizedDynamicFormState[parameterId] = getNormalizedValueFromFormStateBaseProps(
            formValue,
            normalizedParameter,
          );
        } else {
          normalizedDynamicFormState[parameterId] = getNormalizedValueFromFormStateBaseProps(
            formValue,
            normalizedParameter,
          );
        }
      }
    }
  });

  return normalizedDynamicFormState;
}
