import { getCoverageLineConfig } from '@common/config';
import moment from 'moment/moment';
import { useMemo, useState } from 'react';
import { CoverageLine, DatesRangeType } from 'enums';
import { useLocalStorage } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission, SubmissionSearchItem, User } from 'types';
import { FilterElementProps, SearchInputProps, Sorters } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import { submissionDatesRangeConfig } from 'broker/configuration-mappers/submission-dates-range-type-config';
import { useAssigneeFilter } from './useAssigneeFilter';
import { useCoverageLineFilter } from './useCoverageLineFilter';
import { useCustomerFilter } from './useCustomerFilter';
import { useInsuredFilter } from './useInsuredFilter';
import { useStatusFilter } from './useStatusFilter';
import useSubmissionFilter, { SubmissionFilterAttributes } from './useSubmissionFilter';
import { useTypeFilter } from './useTypeFilter';
import {
  FilterType,
  SubmissionBoxStorageKeys,
  SubmissionSortType,
  SubmissionsViewStorageKeys,
  SubmissionViewMode,
} from './utils';

export const sorters: Sorters<SubmissionSearchItem, SubmissionSortType> = {
  [SubmissionSortType.CreatedDateNewest]: {
    sorter: (a, b) => moment(b.createdAt).diff(moment(a.createdAt)),
    label: messages.submissionsView.sorter.createdDateNewest,
  },
  [SubmissionSortType.CreatedDateOldest]: {
    sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
    label: messages.submissionsView.sorter.createdDateOldest,
  },
  [SubmissionSortType.EffectiveDateNewest]: {
    sorter: (a, b) => moment(b.dueDate).diff(moment(a.dueDate)),
    label: messages.submissionsView.sorter.effectiveDateNewest,
  },
  [SubmissionSortType.EffectiveDateOldest]: {
    sorter: (a, b) => moment(a.dueDate).diff(moment(b.dueDate)),
    label: messages.submissionsView.sorter.effectiveDateOldest,
  },
  [SubmissionSortType.Updated]: {
    sorter: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)),
    label: messages.submissionsView.sorter.updated,
  },
  [SubmissionSortType.InsuredAsc]: {
    sorter: (a, b) => (a.insuredName || '').localeCompare(b.insuredName || ''),
    label: messages.submissionsView.sorter.insuredAsc,
  },

  [SubmissionSortType.InsuredDesc]: {
    sorter: (a, b) => (b.insuredName || '').localeCompare(a.insuredName || ''),
    label: messages.submissionsView.sorter.insuredDesc,
  },
  [SubmissionSortType.ReminderDateNewest]: {
    sorter: (a, b) => {
      const dateA = a.mostUrgentNotification?.dueDate ? moment(a.mostUrgentNotification.dueDate) : moment(0); // Convert to Moment object
      const dateB = b.mostUrgentNotification?.dueDate ? moment(b.mostUrgentNotification.dueDate) : moment(0);

      if (dateA.isSame(dateB)) {
        return moment(a.dueDate).diff(moment(b.dueDate));
      }

      return dateB.diff(dateA);
    },
    label: messages.submissionsView.sorter.reminders,
  },
  [SubmissionSortType.ReminderDateOldest]: {
    sorter: (a, b) => {
      const dateA = a.mostUrgentNotification?.dueDate ? moment(a.mostUrgentNotification.dueDate) : moment(0);
      const dateB = b.mostUrgentNotification?.dueDate ? moment(b.mostUrgentNotification.dueDate) : moment(0);
      if (dateA.isSame(dateB)) {
        return moment(b.dueDate).diff(moment(a.dueDate));
      }
      return dateA.diff(dateB);
    },
    label: messages.submissionsView.sorter.remindersOldest,
  },
};

interface SubmissionFiltersProps extends SearchInputProps {
  filteredSubmissions: PartialSubmission[];
  filters: Record<FilterType, SubmissionFilterAttributes>;
  sortType: SubmissionSortType;
  setSortType: (value: SubmissionSortType) => void;
}

const baseDatesRangeFiltersList: FilterElementProps[] = Object.values(DatesRangeType).map((type) => ({
  key: type,
  checked: false,
  label: submissionDatesRangeConfig[type].text,
}));

export default function useSubmissionFilters(
  submissions: PartialSubmission[],
  pinnedSubmissions: string[],
  isLoading: boolean,
  isFetching: boolean,
  teamMembers: User[],
  paginationReset: () => void,
  mode: SubmissionViewMode,
): SubmissionFiltersProps {
  const [sortType, setSortType] = useLocalStorage(
    mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.sort : SubmissionBoxStorageKeys.sort,
    mode === SubmissionViewMode.General ? SubmissionSortType.ReminderDateNewest : SubmissionSortType.CreatedDateOldest,
  );

  const areSubmissionsLoaded = !isFetching && !isLoading;

  const statusFilter = useStatusFilter({ mode, paginationReset });

  const coverageLineFilter = useCoverageLineFilter({
    submissions,
    areSubmissionsLoaded,
    paginationReset,
    mode,
  });

  const assigneeFilter = useAssigneeFilter({
    submissions,
    areSubmissionsLoaded,
    teamMembers,
    paginationReset,
    mode,
  });

  const insuredFilter = useInsuredFilter({
    submissions,
    areSubmissionsLoaded,
    paginationReset,
    mode,
  });

  const typeFilter = useTypeFilter({ paginationReset, mode });

  const customerFilter = useCustomerFilter({
    submissions,
    areSubmissionsLoaded,
    paginationReset,
    mode,
  });

  const dateRangeFilter = useSubmissionFilter({
    baseFiltersList: baseDatesRangeFiltersList,
    storageKey:
      mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.datesRange : SubmissionBoxStorageKeys.datesRange,
    hotjarEvent: HotjarEvents.SubmissionsDatesRangeFilter,
    paginationReset,
  });

  const [search, setSearch] = useState<string>('');
  const filteredSubmissions = useMemo(
    () =>
      submissions
        .filter((submission) => {
          if (insuredFilter.isAllSelected) {
            return true;
          }
          return insuredFilter.filters.some((filter) => filter.key === submission.insuredName && filter.checked);
        })
        .filter((submission) => {
          if (coverageLineFilter.isAllSelected) {
            return true;
          }
          return coverageLineFilter.filters.some(
            (filter) => submission.coverageLines?.includes(filter.key as CoverageLine) && filter.checked,
          );
        })
        .filter((submission) => {
          if (statusFilter.isAllSelected) {
            return true;
          }
          return statusFilter.filters.some((filter) => filter.key === submission.status && filter.checked);
        })
        .filter((submission) => {
          if (typeFilter.isAllSelected) {
            return true;
          }
          return typeFilter.filters.some((filter) => filter.key === submission.type && filter.checked);
        })
        .filter((submission) => {
          if (dateRangeFilter.isAllSelected) {
            return true;
          }
          return dateRangeFilter.filters.some((filter) => {
            if (!submission.dueDate) {
              return false;
            }
            return (
              submissionDatesRangeConfig[filter.key as DatesRangeType].filterFn(submission.dueDate) && filter.checked
            );
          });
        })
        .filter((submission) => {
          if (assigneeFilter.isAllSelected) {
            return true;
          }
          return assigneeFilter.filters.some((filter) => filter.checked && submission.assignee.id === filter.key);
        })
        .filter((submission) => {
          if (search.length < 2) {
            return true;
          }

          const marketTerms = [
            submission.insuredName,
            submission.user?.firstName,
            submission.user?.lastName,
            ...(submission.coverageLines?.map((coverageLine) => getCoverageLineConfig(coverageLine).text) || []),
            submission.organizationName,
            ...(submission.contacts?.flatMap((contact) => [
              contact.firstName,
              contact.lastName,
              contact.organizationName,
            ]) || []),
          ].filter(Boolean);

          return marketTerms.some((term) => term?.toLowerCase().includes(search.toLowerCase()));
        })
        .filter((submission) => {
          if (customerFilter.isAllSelected) {
            return true;
          }
          return customerFilter.filters.some((filter) => filter.key === submission.organizationName && filter.checked);
        })
        .sort(sorters[sortType].sorter)
        .sort(
          mode === SubmissionViewMode.General
            ? (submission, otherSubmission) =>
                Number(pinnedSubmissions.includes(otherSubmission.id)) -
                Number(pinnedSubmissions.includes(submission.id))
            : () => 0,
        ),
    [
      submissions,
      sortType,
      mode,
      insuredFilter.isAllSelected,
      insuredFilter.filters,
      coverageLineFilter.isAllSelected,
      coverageLineFilter.filters,
      statusFilter.isAllSelected,
      statusFilter.filters,
      dateRangeFilter.isAllSelected,
      dateRangeFilter.filters,
      assigneeFilter.isAllSelected,
      assigneeFilter.filters,
      customerFilter.isAllSelected,
      customerFilter.filters,
      typeFilter.isAllSelected,
      typeFilter.filters,
      search,
      pinnedSubmissions,
    ],
  );

  const filters = {
    [FilterType.Insured]: insuredFilter,
    [FilterType.CoverageLine]: coverageLineFilter,
    [FilterType.Status]: statusFilter,
    [FilterType.Assignee]: assigneeFilter,
    [FilterType.DatesRange]: dateRangeFilter,
    [FilterType.Customer]: customerFilter,
    [FilterType.Type]: typeFilter,
  };

  return {
    filters,
    search,
    setSearch,
    sortType,
    setSortType,
    filteredSubmissions,
  };
}
