import { DynamicFormDefaultValue } from '@common/submission-data';
import { isArray } from 'lodash';
import { Stack, Typography } from '@common-components';
import { arraysContainSameValues } from 'utils';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import FormCheckbox from 'components/hookFormComponents/FormCheckbox';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { SuggestionProps } from 'components/hookFormComponents/types';
import IndustryAutoComplete from 'broker/components/common/IndustryAutoComplete';
import DynamicFormAddress from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/components/DynamicFormAddress';
import DynamicFormAreaOfPractice from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/components/DynamicFormAreaOfPractice';
import DynamicFormClaimEvent from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/components/DynamicFormClaimEvent';
import DynamicFormMultiSelectAutocomplete from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/components/DynamicFormMultiSelectAutocomplete';
import { DynamicObjectWrapper } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/components/DynamicObjectWrapper';
import { DynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/enums';
import {
  DynamicQuestion,
  FormStateBaseProp,
  FormStateBaseProps,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { dynamicFormInputTypesConfig } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/utils/dynamic-form-input-types-config';

interface FieldBaseProps {
  name: string;
  label: string;
}

export default function mapDynamicQuestionToFormComponent(
  dynamicQuestion: DynamicQuestion,
  value: FormStateBaseProps | FormStateBaseProps[] | undefined,
  fieldBaseProps: FieldBaseProps,
  dynamicFormDefaultValues: Record<string, DynamicFormDefaultValue>,
  extractionValidationFields: Record<string, boolean>,
  setExtractionValidationFields: (fields: Record<string, boolean>) => void,
  highlightValidationRequired: boolean,
  onBlur: () => boolean | undefined,
  isArrayFields = false,
  resolvedSuggestion?: SuggestionProps,
) {
  const config = dynamicFormInputTypesConfig[dynamicQuestion.inputType];
  const baseProps = {
    name: `${fieldBaseProps.name}.${FormStateBaseProp.Main}`,
    id: `${fieldBaseProps.name}.${FormStateBaseProp.Main}`,
    label: fieldBaseProps.label,
    placeholder: config.placeholder,
    helperText: dynamicQuestion.helperText,
  };

  const baseFieldName = fieldBaseProps.name.split('.')[0];

  const extractionValidationConfirmed = () => {
    setExtractionValidationFields({
      ...extractionValidationFields,
      [baseFieldName]: true,
    });
  };

  const extractionValidationProps =
    baseFieldName in extractionValidationFields
      ? {
          showSuggestionValidation: !extractionValidationFields[baseFieldName],
          suggestionValidationError: highlightValidationRequired,
          suggestionValidationCallback: extractionValidationConfirmed,
        }
      : undefined;

  let component: JSX.Element | null = null;

  // Note: currently, this variable is used only in the basic components, not in the Checkbox, Address and ClaimEvent components
  const isOptional = !dynamicQuestion.requiredForCompletion;
  const dynamicFormDefaultValue = dynamicFormDefaultValues[dynamicQuestion.id];

  const isCurrentValueEqualToDefault = () => {
    // currently we don't support adding default values to array questions (form array fields, not multiple answer questions)
    if (isArrayFields || isArray(value)) {
      return false;
    }
    if (!dynamicFormDefaultValue) {
      return false;
    }
    // if multiple answer question
    if (isArray(value?.[FormStateBaseProp.Main])) {
      if (!isArray(dynamicFormDefaultValue)) {
        return false;
      }
      // check that all question answers are equal to the default value answers
      return value && arraysContainSameValues(value[FormStateBaseProp.Main], dynamicFormDefaultValue);
    }
    return value?.[FormStateBaseProp.Main] === dynamicFormDefaultValue;
  };

  const dynamicFormDefaultValueAsString = Array.isArray(dynamicFormDefaultValue)
    ? dynamicFormDefaultValue.join(', ')
    : dynamicFormDefaultValue?.toString();
  const defaultOverlayText = isCurrentValueEqualToDefault() ? dynamicFormDefaultValueAsString : undefined;

  switch (config.fieldType) {
    case 'TextField':
      component = (
        <FormTextField
          {...baseProps}
          isNumberFormat={config.numberFormat}
          numberFormatProps={{
            prefix: config.isMoney ? undefined : '',
            thousandSeparator: config.thousandSeparator ?? (config.isDecimal || config.isInteger),
            decimalScale: config.isDecimal ? undefined : 0,
          }}
          defaultValueOverlayText={defaultOverlayText}
          onBlur={onBlur}
          optional={isOptional}
          suggestion={resolvedSuggestion}
          enhancedRequired
          suggestionValidation={extractionValidationProps}
        />
      );
      break;
    case 'AutoComplete':
      component = (
        <FormAutocomplete
          {...baseProps}
          onBlur={onBlur}
          options={dynamicQuestion.schema?.enum?.map((item) => ({ label: item, value: item })) || []}
          optional={isOptional}
          defaultValueOverlayText={defaultOverlayText}
          suggestion={resolvedSuggestion}
          enhancedRequired
          suggestionValidation={extractionValidationProps}
        />
      );
      break;
    case 'MultiSelectAutocomplete':
      component = (
        <DynamicFormMultiSelectAutocomplete
          {...baseProps}
          onBlur={onBlur}
          options={dynamicQuestion.schema?.items?.enum?.map((item) => ({ label: item, value: item })) || []}
          optional={isOptional}
          defaultValueOverlayText={defaultOverlayText}
          enhancedRequired
          suggestion={resolvedSuggestion}
          suggestionValidation={extractionValidationProps}
        />
      );
      break;
    case 'DatePicker':
      component = (
        <FormDatePicker
          onChange={onBlur}
          {...baseProps}
          yearsOnly={config.yearsOnly}
          optional={isOptional}
          enhancedRequired
          suggestion={resolvedSuggestion}
          suggestionValidation={extractionValidationProps}
          defaultValueOverlayText={defaultOverlayText}
        />
      );
      break;
    case 'Checkbox': {
      component = (
        <FormCheckbox
          onBlur={onBlur}
          {...baseProps}
          color="secondary"
          checkedValue={dynamicQuestion.schema?.enum![0]}
        />
      );
      // agree to also has additional text above the checkbox
      if (dynamicQuestion.inputType === DynamicInputType.AgreeTo) {
        component = (
          <Stack mb={1}>
            <Stack py={2} bgcolor="grey.50">
              <Stack px={2} maxHeight={200} sx={{ overflowY: 'scroll' }}>
                <Typography variant="body2">{dynamicQuestion.additionalContent}</Typography>
              </Stack>
            </Stack>

            {component}
          </Stack>
        );
        break;
      }
      break;
    }
    case 'Address':
      return (
        <DynamicObjectWrapper
          onBlur={onBlur}
          dynamicQuestion={dynamicQuestion}
          fieldNamePrefix={fieldBaseProps.name}
          hideBorder={isArrayFields}
          optional={isOptional}
          suggestion={resolvedSuggestion}
          FieldsComponent={DynamicFormAddress}
          suggestionValidation={extractionValidationProps}
        />
      );
    case 'ClaimEvent':
      return (
        <DynamicObjectWrapper
          hideBorder={isArrayFields}
          onBlur={onBlur}
          dynamicQuestion={dynamicQuestion}
          fieldNamePrefix={fieldBaseProps.name}
          optional={isOptional}
          FieldsComponent={DynamicFormClaimEvent}
        />
      );
    case 'AreaOfPractice':
      return (
        <DynamicObjectWrapper
          hideBorder={isArrayFields}
          onBlur={onBlur}
          dynamicQuestion={dynamicQuestion}
          fieldNamePrefix={fieldBaseProps.name}
          optional={isOptional}
          FieldsComponent={DynamicFormAreaOfPractice}
          suggestion={resolvedSuggestion}
          suggestionValidation={extractionValidationProps}
        />
      );
    case 'IndustryAutoComplete':
      return (
        <IndustryAutoComplete
          fieldProps={baseProps}
          onBlur={onBlur}
          enhancedRequired
          suggestionValidation={extractionValidationProps}
          suggestion={resolvedSuggestion}
        />
      );
    default:
      component = <Typography>{dynamicQuestion.fieldLabel}</Typography>;
  }
  return component;
}
