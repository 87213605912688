import { get } from 'lodash';
import { FieldValues } from 'react-hook-form';
import {
  DynamicQuestion,
  FormStateBaseProps,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

export const buildFieldName = (
  fieldNamePrefix: string,
  fieldName: string,
  placeholder: string,
  dynamicQuestion: DynamicQuestion,
) => {
  const fields = dynamicQuestion.schema?.properties!;
  const name = `${fieldNamePrefix}.${fieldName}`;
  return {
    name,
    id: name,
    label: `${(dynamicQuestion.schema?.required?.includes(fieldName) ? '*' : '') + fields[fieldName]!.title}`,
    placeholder,
    required: true,
  };
};

// rsk_yor8_location.0.children.rsk_52it_fte_by_location -> rsk_yor8_location[0].children.rsk_52it_fte_by_location
function adjustPathForLodash(path: string) {
  return path.replace(/\.?(\d+)(?=\.)/g, '[$1]').replace(/\.?(\d+)$/, '[$1]');
}

// fieldPath example: rsk_yor8_location.0.children.rsk_52it_fte_by_location
export function getDynamicValueFromFieldPath(formValues: FieldValues, fieldPath: string) {
  return get(formValues, adjustPathForLodash(fieldPath)) as FormStateBaseProps | undefined;
}
