import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

interface GetDynamicQuestionsProps {
  flowDynamicQuestions: DynamicQuestion[];
  heraldDynamicQuestions: DynamicQuestion[];
}

export function getDynamicQuestions({ heraldDynamicQuestions, flowDynamicQuestions }: GetDynamicQuestionsProps) {
  let internalFlowDynamicQuestions = [...flowDynamicQuestions];
  const unitedQuestions = [];
  for (const heraldDynamicQuestion of heraldDynamicQuestions) {
    unitedQuestions.push(heraldDynamicQuestion);
    // Find all flowDynamicQuestions that should be shown after this heraldDynamicQuestion
    const matchingFlowStaticQuestions = internalFlowDynamicQuestions.filter((flowStaticQuestion) =>
      flowStaticQuestion.showAfterOneOfTheseQuestions?.includes(heraldDynamicQuestion.id),
    );

    // Add all matching flowDynamicQuestions to unitedQuestions
    unitedQuestions.push(...matchingFlowStaticQuestions);

    // Remove the added questions from internalFlowDynamicQuestions
    internalFlowDynamicQuestions = internalFlowDynamicQuestions.filter(
      (question) => !matchingFlowStaticQuestions.includes(question),
    );
  }
  return [...unitedQuestions, ...internalFlowDynamicQuestions];
}
