import { isNil } from 'lodash';
import { CoverageValuesUpdate, RiskValuesUpdate } from '../types/herald/herald-types';
import { ExtractedData } from '../types';
import { ExtractedDataField, submissionExtractedDataToSubmissionDataResolver } from './submission-extracted-data';
import { SubmissionDataDestination } from './enums';
import { addDefaultsToHeraldApplication } from './submission-defaults';

type HeraldApplicationValues = {
    risk_values: RiskValuesUpdate[];
    coverage_values: CoverageValuesUpdate[];
};

function getHeraldApplicationCreateDataFromExtraction(submissionExtractedData: ExtractedData): HeraldApplicationValues {
    const data: HeraldApplicationValues = {
        risk_values: [],
        coverage_values: [],
    };
    const submissionExtractedDataToSubmissionData =
        submissionExtractedDataToSubmissionDataResolver(submissionExtractedData);

    Object.keys(submissionExtractedDataToSubmissionData).forEach((key) => {
        const dataFields = submissionExtractedDataToSubmissionData[key as keyof ExtractedDataField];
        dataFields?.forEach((dataField) => {
            if (dataField?.destination === SubmissionDataDestination.Herald) {
                const valueToUpdate = dataField.value;
                // filter herald questions that don't really have an answer
                if (!isNil(valueToUpdate.value) && valueToUpdate.value !== '') {
                    if ((valueToUpdate as RiskValuesUpdate).risk_parameter_id) {
                        data.risk_values.push(valueToUpdate as RiskValuesUpdate);
                    } else {
                        data.coverage_values.push(valueToUpdate as CoverageValuesUpdate);
                    }
                }
            }
        });
    });

    return data;
}

export function getHeraldApplicationCreateData(submissionExtractedData?: ExtractedData): HeraldApplicationValues {
    const heraldApplicationCreateDataFromExtraction = submissionExtractedData
        ? getHeraldApplicationCreateDataFromExtraction(submissionExtractedData)
        : {
              risk_values: [],
              coverage_values: [],
          };

    return addDefaultsToHeraldApplication(heraldApplicationCreateDataFromExtraction, {
        extractedData: submissionExtractedData,
    });
}
