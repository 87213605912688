import {
  isManagementLiabilityCoverage,
  ManagementLiabilityCoverageLines,
  managementLiabilityCoverageLineToHeraldCoveragesConfig,
} from '@common/config';
import { getHeraldApplicationCreateData } from '@common/submission-data';
import { ExtractedData, heraldQuestionId } from '@common/types';
import { HeraldApiError, HeraldApplicationResponse, HeraldUpdateApplication } from '@common/types/herald/herald-types';
import {
  convertCoverageValueToLean,
  convertRiskValueToLean,
  getHeraldSelectedCoveragesQuestionsAnswers,
} from '@common/utils';
import { isEmpty, uniq } from 'lodash';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { CoverageLine } from 'enums';
import { useHeraldApplicationApi, useRetrieveHeraldApplication, useToast } from 'hooks';
import { messages } from 'i18n';
import { mapFlowQuestionsToHerald } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/providers/flow/mappers/flow-questions-config-mappers';
import { useGetApiProductsByCoverageLines, useIsBORFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { overrideHeraldApplicationDataWithSubmissionData } from 'broker/pages/SubmissionWorkspacePage/utils';
import { PostSubmissionCoverageLineUpdateProps } from 'broker/pages/SubmissionWorkspacePage/utils/types';

export function useUpdateHeraldApplicationWithSubmissionCoverageLines() {
  const { showToast } = useToast();
  const { isBOR } = useIsBORFlow();
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();

  const { getApiProductsByCoverageLines } = useGetApiProductsByCoverageLines();
  const { refetch: fetchHeraldApplication } = useRetrieveHeraldApplication({
    id: partialSubmission?.heraldData?.applicationId || '',
    enabled: false,
  });
  const { upsertApplication } = useHeraldApplicationApi();

  const upsertHeraldApplicationAttempt = async (
    upsertData: HeraldUpdateApplication,
    existingApplicationId?: string,
  ) => {
    try {
      return await upsertApplication.mutateAsync({
        existingApplicationId,
        upsertData,
      });
    } catch (e: unknown) {
      const fieldErrors = e && typeof e === 'object' && 'errors' in e ? (e as HeraldApiError).errors : undefined;

      if (e instanceof ForbiddenError) {
        showToast('error', { message: e.message });
      } else if (fieldErrors && !isEmpty(fieldErrors)) {
        showToast('error', { message: messages.editSubmission.heraldAPiError });
      } else {
        showToast('error', { message: messages.editSubmission.heraldAPiError });
      }
    }
    return null;
  };

  const upsertHeraldApplication = async (
    externalProductIds: string[],
    updatedCoverageLines: CoverageLine[],
    heraldMLCoverages: string[],
    extractedData?: ExtractedData,
    heraldDataFromPrevNonHeraldSubmission?: Omit<HeraldUpdateApplication, 'products'>,
    existingApplicationId?: string,
  ) => {
    const existingApplication = existingApplicationId ? await fetchHeraldApplication() : undefined;

    let upsertData: HeraldUpdateApplication = {
      products: externalProductIds,
    };

    // if we create herald application for the first time, we need to add the default values and extracted data
    if (!existingApplication) {
      upsertData = {
        ...upsertData,
        ...getHeraldApplicationCreateData(extractedData),
      };
    } else {
      upsertData = {
        ...upsertData,
        coverage_values: existingApplication?.data?.application.coverage_values.map(convertCoverageValueToLean) || [],
        risk_values: existingApplication?.data?.application.risk_values.map(convertRiskValueToLean) || [],
      };
    }

    let updatedCoverageValues = upsertData.coverage_values || [];
    const heraldSelectedCoveragesQuestionsAnswers = getHeraldSelectedCoveragesQuestionsAnswers(updatedCoverageLines);
    const heraldSelectedCoveragesLinesQuestions = heraldSelectedCoveragesQuestionsAnswers.map(
      (item) => item.coverage_parameter_id,
    );
    updatedCoverageValues = isEmpty(heraldMLCoverages)
      ? updatedCoverageValues
      : [
          ...updatedCoverageValues.filter(
            (value) =>
              value.coverage_parameter_id !== heraldQuestionId.cvg_0e4f_ml_selected_coverages &&
              !heraldSelectedCoveragesLinesQuestions.includes(value.coverage_parameter_id),
          ),
          {
            coverage_parameter_id: heraldQuestionId.cvg_0e4f_ml_selected_coverages,
            value: heraldMLCoverages,
          },
          ...heraldSelectedCoveragesQuestionsAnswers,
        ];

    upsertData.coverage_values = updatedCoverageValues;

    upsertData = heraldDataFromPrevNonHeraldSubmission
      ? overrideHeraldApplicationDataWithSubmissionData(upsertData, heraldDataFromPrevNonHeraldSubmission)
      : upsertData;

    return upsertHeraldApplicationAttempt(upsertData, existingApplicationId);
  };

  /**
   * Update the Herald application with the updated coverage lines from the submission
   * @param PostSubmissionCoverageLineUpdateProps - object - PostSubmissionCoverageLineUpdateProps: the updated coverage lines from the submission oldCoverageLines: the old coverage lines from the submission before update, wasHeraldSubmission - if the submission before the update was a herald submission
   * @returns  string[]|undefined: the external product ids that are associated with the updated coverage lines or undefined if there was an error trying to update the application with the products
   */
  return async ({
    oldCoverageLines,
    updatedCoverageLines,
    wasHeraldSubmission,
  }: PostSubmissionCoverageLineUpdateProps) => {
    // bor does not render herald application
    if (isBOR) {
      return [];
    }
    const externalProduct = await getApiProductsByCoverageLines(updatedCoverageLines);
    const externalProductIds = uniq(externalProduct.map((product) => product.externalProductId)) as string[];

    const heraldMLCoverages = uniq(
      updatedCoverageLines
        .filter((coverageLine) => isManagementLiabilityCoverage(coverageLine))
        .map(
          (coverageLine) =>
            managementLiabilityCoverageLineToHeraldCoveragesConfig[coverageLine as ManagementLiabilityCoverageLines],
        ),
    );

    if (externalProductIds.length > 0) {
      const heraldDataFromPrevNonHeraldSubmission = !wasHeraldSubmission
        ? mapFlowQuestionsToHerald(partialSubmission!)
        : undefined;

      let heraldResponse: HeraldApplicationResponse | null = null;
      if (partialSubmission?.heraldData) {
        heraldResponse = await upsertHeraldApplication(
          externalProductIds,
          updatedCoverageLines,
          heraldMLCoverages,
          partialSubmission.submissionExtractedData?.extractedData,
          heraldDataFromPrevNonHeraldSubmission,
          partialSubmission.heraldData.applicationId,
        );
      } else {
        heraldResponse = await upsertHeraldApplication(
          externalProductIds,
          updatedCoverageLines,
          heraldMLCoverages,
          partialSubmission?.submissionExtractedData?.extractedData,
          heraldDataFromPrevNonHeraldSubmission,
        );
      }
      if (!heraldResponse) {
        // failed to update the application with the products, rollback the coverage lines to the old ones
        await updateSubmission(partialSubmission!.id, { coverageLines: oldCoverageLines || [] });
        // this shouldn't happen, but if there was an error trying to call herald and no exception was thrown, we return undefined
        return undefined;
      }
      await updateSubmission(partialSubmission!.id, {
        heraldData: {
          applicationId: heraldResponse.application.id,
          status: heraldResponse.application.status,
          products: heraldResponse.application.products,
          requiredQuestionsBeforeQuoteExit:
            heraldResponse.quote_exits?.length && partialSubmission?.heraldData?.requiredQuestionsBeforeQuoteExit
              ? partialSubmission?.heraldData?.requiredQuestionsBeforeQuoteExit
              : undefined,
        },
      });
    }
    // if there are no external product ids, we archive the herald data in the submission if it existed before
    else if (partialSubmission?.heraldData && !partialSubmission.heraldData.isArchived) {
      await updateSubmission(partialSubmission.id, {
        heraldData: { ...partialSubmission.heraldData, isArchived: true },
      });
    }
    return externalProductIds;
  };
}
