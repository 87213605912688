import { compact } from 'lodash';
import moment from 'moment-timezone';
import { getCoverageLineConfig, getCoverageLinesConfig } from '../config';
import { CoverageLine } from '../enums';
import { addXBusinessDaysFromDate, getUTCStartOfDayFromTimezone } from './date-utils';

export function convertCoverageLineTextToEnum(coverageLineText: string) {
    const lowerCaseCoverageLineText = coverageLineText.toLowerCase();
    const matchingEntry = Object.entries(getCoverageLinesConfig()).find((entry) => {
        const currentCoverageLineText = entry[1].text.toLowerCase();
        if (currentCoverageLineText === lowerCaseCoverageLineText) {
            return true;
        }
        // If the coverage line text contains a parenthetical, remove it and compare the text again
        return currentCoverageLineText.replace(/\s?\([^)]*\)/g, '') === lowerCaseCoverageLineText;
    });
    return matchingEntry ? (matchingEntry[0] as CoverageLine) : undefined;
}

export function convertCoverageLinesTextToEnum(coverageLines: string[]) {
    return compact(coverageLines.map((coverageLine) => convertCoverageLineTextToEnum(coverageLine))) as CoverageLine[];
}

export function replaceLastCommaWithEnd(text: string) {
    // adding 'and' before the last list item
    const replacement = ` and`;
    return text.replace(/,([^,]*)$/, `${replacement}$1`);
}

interface CoverageLinesTextProps {
    replaceLastCommaWithEnd?: boolean;
}

export function getCoverageLinesText(coverages?: CoverageLine[], additionalProps?: CoverageLinesTextProps) {
    const coverageLinesText =
        coverages && coverages.length > 0
            ? coverages.map((coverage) => getCoverageLineConfig(coverage).text).join(', ')
            : '';
    if (additionalProps?.replaceLastCommaWithEnd) {
        return replaceLastCommaWithEnd(coverageLinesText);
    }
    return coverageLinesText;
}

export const getDefaultSubmissionNeedByDate = (): moment.Moment =>
    addXBusinessDaysFromDate(getUTCStartOfDayFromTimezone(), 3);
