import { compact, uniq } from 'lodash';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { DynamicQuestion, FormStateBaseProps } from './types';

interface UseGetSectionsIndicatorsProps {
  activeSection?: string;
  answeredDynamicQuestionsBySections: Record<string, { total: number; answered: number }>;
  sectionList: string[];
  dynamicQuestions: DynamicQuestion[];
  methods: UseFormReturn<Record<string, FormStateBaseProps | FormStateBaseProps[]>, object>;
}

export function useGetSectionsIndicators({
  answeredDynamicQuestionsBySections,
  dynamicQuestions,
  methods,
}: UseGetSectionsIndicatorsProps) {
  // Calculate the number of errors by counting the keys in methods.formState.errors
  const errorCount = Object.keys(methods.formState.errors).length;

  const totalUnansweredQuestions = useMemo(
    () =>
      Object.keys(answeredDynamicQuestionsBySections).reduce(
        (acc, section) =>
          acc +
          (answeredDynamicQuestionsBySections[section].total - answeredDynamicQuestionsBySections[section].answered),
        0,
      ),
    [answeredDynamicQuestionsBySections],
  );

  const sectionsWithErrors = useMemo(() => {
    // Gather actual validation errors from formState
    const validationErrors = compact(
      Object.keys(methods.formState.errors).map((parameterId) => {
        const filteredDynamicQuestionItem = dynamicQuestions.find(
          (filteredDynamicQuestion) => filteredDynamicQuestion.id === parameterId,
        );

        if (filteredDynamicQuestionItem) {
          return filteredDynamicQuestionItem.section; // Return the section that has an error
        }

        return undefined;
      }),
    );

    // Combine only validation errors, excluding missing questions as errors
    return uniq(validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicQuestions, methods.formState.errors, errorCount]); // Track changes in the number of errors

  return {
    sectionsWithErrors,
    totalUnansweredQuestions,
  };
}
