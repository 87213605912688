import { DynamicFormDefaultValue } from '@common/submission-data';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@common-components';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import { SuggestionProps } from 'components/hookFormComponents/types';
import mapDynamicQuestionToFormComponent from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/mappers/map-dynamic-question-to-form-component';
import { DynamicQuestion } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { getDynamicValueFromFieldPath } from './utils/utils';

export interface BaseDynamicFormArrayFieldsProps {
  dynamicQuestion: DynamicQuestion;
  onBlur: () => boolean | undefined;
  extractionValidationFields: Record<string, boolean>;
  setExtractionValidationFields: (fields: Record<string, boolean>) => void;
  highlightValidationRequired: boolean;
  resolvedSuggestion?: SuggestionProps;
  dynamicFormDefaultValues: Record<string, DynamicFormDefaultValue>;
}

export interface BaseDynamicFormFieldsValue {
  main: any;
  children?: any[];
}
export const defaultDynamicFormAddressArrayValue: BaseDynamicFormFieldsValue = {
  main: {
    line1: '',
    line2: '',
    line3: '',
    organization: '',
    city: '',
    state: '',
    postal_code: '',
    country_code: '',
  },
};

export const defaultDynamicFormClaimEventArrayValue = {
  main: {
    description: '',
    date: '',
    amount: '',
  },
};

interface DynamicFormArrayFieldsProps extends BaseDynamicFormArrayFieldsProps, FormArrayFieldProps {}

const DynamicFormArrayFields: FC<DynamicFormArrayFieldsProps> = ({
  getFieldName,
  dynamicQuestion,
  index,
  extractionValidationFields,
  setExtractionValidationFields,
  highlightValidationRequired,
  onBlur,
  resolvedSuggestion,
  dynamicFormDefaultValues,
}: DynamicFormArrayFieldsProps) => {
  const { getValues } = useFormContext();
  const values = getValues();
  const fieldPath = getFieldName('');

  const value = getDynamicValueFromFieldPath(values, fieldPath);

  const resolvedSuggestionForArrayField = useMemo(
    () =>
      resolvedSuggestion?.value && Array.isArray(resolvedSuggestion?.value)
        ? { ...resolvedSuggestion, value: resolvedSuggestion.value[index] }
        : undefined,
    [index, resolvedSuggestion],
  );

  const parentJsx = mapDynamicQuestionToFormComponent(
    dynamicQuestion,
    value,
    {
      name: getFieldName(''),
      label: dynamicQuestion.fieldLabel,
    },
    dynamicFormDefaultValues,
    extractionValidationFields,
    setExtractionValidationFields,
    highlightValidationRequired,
    onBlur,
    true,
    resolvedSuggestionForArrayField,
  );

  const arrayElement = dynamicQuestion.arrayElements![index];

  const childrenJSx = arrayElement?.childValues?.map((childDynamicQuestion) => {
    const childFieldPath = getFieldName(`children.${childDynamicQuestion.id}`);
    const childFieldValue = getDynamicValueFromFieldPath(values, childFieldPath);
    // if a repeated question has a repeated child question, then render FormArrayFields for the child question
    return childDynamicQuestion.arrayElements ? (
      <FormArrayFields
        key={childDynamicQuestion.id}
        name={childFieldPath}
        defaultValue={{}}
        FormArrayFieldComponent={DynamicFormArrayFields as FC<FormArrayFieldProps>}
        additionalProps={{ dynamicQuestion: childDynamicQuestion } as BaseDynamicFormArrayFieldsProps}
      />
    ) : (
      mapDynamicQuestionToFormComponent(
        childDynamicQuestion,
        childFieldValue,
        {
          name: getFieldName(`children.${childDynamicQuestion.id}`),
          label: childDynamicQuestion.fieldLabel,
        },
        dynamicFormDefaultValues,
        extractionValidationFields,
        setExtractionValidationFields,
        highlightValidationRequired,
        onBlur,
        false,
      )
    );
  });

  return (
    <Stack>
      {parentJsx}
      {childrenJSx || null}
    </Stack>
  );
};

export default DynamicFormArrayFields;
