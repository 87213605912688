import { ComponentType } from 'react';
import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { SuggestionProps, SuggestionValidation } from 'components/hookFormComponents/types';
import {
  DynamicQuestion,
  FormStateBaseProp,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { buildFieldName } from './utils/utils';

interface DynamicObjectWrapperProps {
  dynamicQuestion: DynamicQuestion;
  fieldNamePrefix: string;
  onBlur: () => boolean | undefined;
  hideBorder: boolean;
  optional?: boolean;
  FieldsComponent: ComponentType<DynamicObjectFieldsComponentProps>;
  suggestion?: SuggestionProps;
  suggestionValidation?: SuggestionValidation;
}

export interface DynamicObjectFieldsComponentProps extends DynamicObjectWrapperProps {
  ownBuildName: (
    fieldName: string,
    placeholder: string,
  ) => { name: string; id: string; label: string; placeholder: string; required: boolean };
}

export function DynamicObjectWrapper(props: DynamicObjectWrapperProps) {
  const { dynamicQuestion, fieldNamePrefix, hideBorder, optional, FieldsComponent } = props;
  const ownBuildName = (fieldName: string, placeholder: string) =>
    buildFieldName(`${fieldNamePrefix}.${FormStateBaseProp.Main}`, fieldName, placeholder, dynamicQuestion);

  return (
    <Stack gap={1} mb={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography mb={1} variant="body2Bold" textTransform="capitalize">
          {dynamicQuestion.fieldLabel}
        </Typography>
        {optional && (
          <Typography variant="body2" color="textSecondary" mr={3}>
            {messages.general.optional}
          </Typography>
        )}
      </Stack>

      <Stack
        sx={
          !hideBorder
            ? {
                border: 1,
                borderColor: 'divider',
                borderRadius: 2,
              }
            : undefined
        }
        p={hideBorder ? 0 : 2}
      >
        <FieldsComponent {...props} ownBuildName={ownBuildName} />
      </Stack>
    </Stack>
  );
}
