import { isEmpty } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { Chip, Collapse, Stack, Typography } from '@common-components';
import { LocalStorage } from 'enums';
import { useLocalStorage } from 'hooks';
import { PartialSubmission } from 'types';
import { showFastTrackIndication, userFullName } from 'utils';
import { WorkspaceTabsRoutesValues } from 'broker/broker-routes';
import FastTrackIconButton from 'broker/components/FastTrackIconButton';
import { SubmissionViewMode } from 'broker/components/SubmissionsView/utils';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import { submissionTypeConfig } from 'broker/configuration-mappers/submission-type-config';
import { useGetSubmissionCreationEmail } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import FastTrackBanner from './FastTrackBanner';
import NavigationTabs from './NavigationTabs';
import RenewalDemoIconButton from './RenewalDemo/RenewalDemoIconButton';
import SubmissionDetails from './SubmissionDetails';
import { SubmissionHeaderMenu } from './SubmissionHeaderMenu';

interface HeaderProps {
  activeTab: WorkspaceTabsRoutesValues;
  partialSubmission: PartialSubmission;
}

export default function Header({ activeTab, partialSubmission }: HeaderProps) {
  const { submissionCreationEmail } = useGetSubmissionCreationEmail();
  const submissionName = partialSubmission.insuredName || submissionCreationEmail?.subject || '';
  const { innerPageState } = useUiStoreState();
  const [fastTrackBannerSubmissionsDismissed, setFastTrackBannerSubmissionsDismissed] = useLocalStorage<string[]>(
    LocalStorage.FastTrackBannerSubmissionsDismissed,
    [],
  );
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeoutRef = useRef<number | null>(null);
  const leaveTimeoutRef = useRef<number | null>(null);

  const onFastTrackBannerDismiss = () => {
    setFastTrackBannerSubmissionsDismissed([...fastTrackBannerSubmissionsDismissed, partialSubmission.id]);
  };

  const handleMouseEnter = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
    }
    hoverTimeoutRef.current = window.setTimeout(() => {
      setIsHovered(true);
    }, 500);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    leaveTimeoutRef.current = window.setTimeout(() => {
      setIsHovered(false);
    }, 1000);
  }, []);

  const isTitleCollapsed = !isEmpty(innerPageState) ? !isHovered : false;

  const showFastTrackBanner =
    showFastTrackIndication(partialSubmission) && !fastTrackBannerSubmissionsDismissed.includes(partialSubmission.id);

  return (
    <Stack
      bgcolor="common.white"
      px={3}
      pt={2}
      width={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showFastTrackBanner && <FastTrackBanner onBannerDismiss={onFastTrackBannerDismiss} />}

      <Stack direction="row" alignItems="center" justifyContent="space-between" width={1} mb={2}>
        <Stack direction="column" flex={1} maxWidth={980}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h3" component="h1" color="typography.contrast">
              {submissionName}{' '}
              {partialSubmission?.insuredPrimaryAddress?.state &&
                `(${partialSubmission?.insuredPrimaryAddress?.state})`}
            </Typography>
            <Chip
              color={submissionStatusConfig[partialSubmission.status].bgColor}
              size="small"
              label={submissionStatusConfig[partialSubmission.status].text(SubmissionViewMode.General)}
              processing={submissionStatusConfig[partialSubmission.status].inProgressStatus}
            />
            <Chip color="grey.200" size="small" label={submissionTypeConfig[partialSubmission.type].text} />
          </Stack>

          <Collapse in={!isTitleCollapsed} timeout={{ enter: 200, exit: 500 }}>
            {!isEmpty(partialSubmission.coverageLines) && <SubmissionDetails partialSubmission={partialSubmission} />}
            <Typography variant="caption" color="typography.nonEssential">
              {partialSubmission.user && `${userFullName(partialSubmission.user)}, `}{' '}
              {partialSubmission.organizationName}
            </Typography>
          </Collapse>
        </Stack>
        <Stack direction="row" alignItems="center">
          {showFastTrackIndication(partialSubmission) && !partialSubmission.isDemo && <FastTrackIconButton />}
          {partialSubmission.isDemo && <RenewalDemoIconButton />}
          <SubmissionHeaderMenu submissionCreationEmail={submissionCreationEmail} submission={partialSubmission} />
        </Stack>
      </Stack>

      <Collapse in={isEmpty(innerPageState)}>
        <NavigationTabs activeTab={activeTab} />
      </Collapse>
    </Stack>
  );
}
