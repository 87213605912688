import { heraldQuestionId } from '@common/types';
import {
  CoverageValuesUpdate,
  HeraldApiError,
  HeraldFormInputType,
  HeraldUpdateApplication,
  RiskValuesUpdate,
} from '@common/types/herald/herald-types';
import { isNumber, toNumber, uniq } from 'lodash';
import { HeraldCategoryType } from 'enums';
import { DynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/enums';
import {
  DynamicQuestion,
  FormStateBaseProp,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { parseUpdateErrorInvalidFormatItem } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/utils/validations-utils';
import { HeraldApplicationNormalized } from './types';

export function getHeraldServerErrorFieldMessage(
  error: HeraldApiError,
  formStateToHerald: Omit<HeraldUpdateApplication, 'products'>,
) {
  if (error.errors?.[0].code === 'invalid_format') {
    const errorItem = error.errors[0];
    // e.g risk_values[0].value.date || risk_values[13].child_risk_values[0].value
    const path = errorItem.path.split('.');
    const suffix = path[path.length - 1] === 'value' ? '' : `.${path[path.length - 1]}`;
    const parsedParameter = parseUpdateErrorInvalidFormatItem(path[0]);
    let paramIdType: HeraldCategoryType | undefined;
    if (parsedParameter?.[0] === HeraldCategoryType.RiskValues) {
      paramIdType = HeraldCategoryType.RiskValues;
    } else if (parsedParameter?.[0] === HeraldCategoryType.CoverageValues) {
      paramIdType = HeraldCategoryType.CoverageValues;
    }
    if (paramIdType && parsedParameter && isNumber(parsedParameter?.[1])) {
      const paramValue = formStateToHerald[paramIdType]?.[parsedParameter[1]];
      if (paramValue) {
        let fieldName =
          paramIdType === HeraldCategoryType.RiskValues
            ? (paramValue as RiskValuesUpdate).risk_parameter_id
            : (paramValue as CoverageValuesUpdate).coverage_parameter_id;

        if (paramValue.instance) {
          const index = toNumber(paramValue.instance.split('_').pop()) - 1;
          fieldName += `[${index}]`;
        }

        if (path[1] === 'value') {
          return {
            fieldName: `${fieldName}.${FormStateBaseProp.Main}${suffix}`,
            message: errorItem.display_message,
          };
        }
        if (path[1].includes('child_')) {
          const parsedChildParameter = parseUpdateErrorInvalidFormatItem(path[1]);
          if (parsedChildParameter?.[0] && isNumber(parsedChildParameter?.[1])) {
            const childParamValue =
              paramIdType === HeraldCategoryType.RiskValues
                ? (paramValue as RiskValuesUpdate)?.child_risk_values?.[parsedChildParameter[1]]
                : (paramValue as CoverageValuesUpdate)?.child_coverage_values?.[parsedChildParameter[1]];

            const childParamFieldName =
              paramIdType === HeraldCategoryType.RiskValues
                ? (paramValue as RiskValuesUpdate)?.child_risk_values?.[parsedChildParameter[1]].risk_parameter_id
                : (paramValue as CoverageValuesUpdate)?.child_coverage_values?.[parsedChildParameter[1]]
                    .coverage_parameter_id;

            fieldName += `.${FormStateBaseProp.Children}.${childParamFieldName}`;

            if (childParamValue) {
              if (childParamValue.instance) {
                const index = toNumber(childParamValue.instance.split('_').pop()) - 1;
                fieldName += `[${index}]`;
              }
              return {
                fieldName: `${fieldName}.${FormStateBaseProp.Main}${suffix}`,
                message: errorItem.display_message,
              };
            }
          }
        }
      }
    }
  }
  return false;
}

export function getHeraldRequiredQuestionIds(heraldApplication: HeraldApplicationNormalized) {
  const requiredQuestionIds: string[] = [];

  function setValuesRequiredQuestionId(normalizedParameter: DynamicQuestion) {
    if (normalizedParameter.requiredForCompletion) {
      requiredQuestionIds.push(normalizedParameter.id);
    }
    if (normalizedParameter.childValues) {
      normalizedParameter.childValues.forEach(setValuesRequiredQuestionId);
    }
  }

  heraldApplication.risk_values.forEach(setValuesRequiredQuestionId);
  heraldApplication.coverage_values.forEach(setValuesRequiredQuestionId);
  // herald array has the question repeat itself so added uniq to remove duplicates
  return uniq(requiredQuestionIds);
}

export function mapHeraldInputTypeToDynamicInputType(
  parameterId: string,
  inputType: HeraldFormInputType,
): DynamicInputType {
  if (parameterId === heraldQuestionId.rsk_b3jm_2017_naics_index) {
    return DynamicInputType.Industry;
  }
  switch (inputType) {
    case HeraldFormInputType.SelectOne:
      return DynamicInputType.SelectOne;
    case HeraldFormInputType.SelectMany:
      return DynamicInputType.SelectMany;
    case HeraldFormInputType.AgreeTo:
      return DynamicInputType.AgreeTo;
    case HeraldFormInputType.ShortText:
      return DynamicInputType.ShortText;
    case HeraldFormInputType.LongText:
      return DynamicInputType.LongText;
    case HeraldFormInputType.Number:
      return DynamicInputType.Number;
    case HeraldFormInputType.Integer:
      return DynamicInputType.Integer;
    case HeraldFormInputType.Currency:
      return DynamicInputType.Currency;
    case HeraldFormInputType.Date:
      return DynamicInputType.Date;
    case HeraldFormInputType.Address:
      return DynamicInputType.Address;
    case HeraldFormInputType.ClaimEvent:
      return DynamicInputType.ClaimEvent;
    case HeraldFormInputType.Phone:
      return DynamicInputType.Phone;
    case HeraldFormInputType.Email:
      return DynamicInputType.Email;
    case HeraldFormInputType.Domain:
      return DynamicInputType.Domain;
    case HeraldFormInputType.File:
      return DynamicInputType.File;
    case HeraldFormInputType.Year:
      return DynamicInputType.Year;
    default:
      return DynamicInputType.ShortText;
  }
}
