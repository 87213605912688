import { FlowQuestionId } from '@common/types';
import { HeraldUpdateApplication } from '@common/types/herald/herald-types';
import { getHeraldQuestionIdCategory } from '@common/utils';
import { isEmpty, isNil } from 'lodash';
import { HeraldCategoryType } from 'enums';
import { PartialSubmission } from 'types';
import { IndustryAutoCompleteOption } from 'broker/components/common/IndustryAutoComplete/types';
import { normalizeDynamicFormValueAccordingToSchema } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/utils/mapping-utils';
import { flowQuestionsConfig } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/providers/flow/flow-questions/flow-questions-config';
import { flowQuestionsConfigMapper } from './flow-questions-config-mapper';

export const mapFlowQuestionsToHerald = (
  partialSubmission: PartialSubmission,
): Omit<HeraldUpdateApplication, 'products'> => {
  const heraldUpdateApplication: Required<Omit<HeraldUpdateApplication, 'products'>> = {
    risk_values: [],
    coverage_values: [],
  };
  Object.entries(flowQuestionsConfigMapper).forEach(([flowQuestionId, mapperConfig]) => {
    const heraldConfig = mapperConfig.herald;
    if (!heraldConfig || isEmpty(heraldConfig)) {
      return;
    }
    const config = flowQuestionsConfig.find((q) => q.id === flowQuestionId);
    if (!config) {
      return;
    }
    let flowValue = config.getValue(partialSubmission);
    flowValue =
      flowQuestionId === FlowQuestionId.Industry
        ? (flowValue as IndustryAutoCompleteOption | undefined)?.heraldId
        : flowValue;
    const normalizedHeraldValue = normalizeDynamicFormValueAccordingToSchema(flowValue, config.schema);
    if (isNil(normalizedHeraldValue)) {
      return;
    }
    heraldConfig.forEach(({ id, instance }) => {
      if (getHeraldQuestionIdCategory(id) === HeraldCategoryType.RiskValues) {
        heraldUpdateApplication.risk_values.push({
          risk_parameter_id: id,
          instance,
          value: normalizedHeraldValue,
        });
      } else {
        heraldUpdateApplication.coverage_values.push({
          coverage_parameter_id: id,
          instance,
          value: normalizedHeraldValue,
        });
      }
    });
  });

  return heraldUpdateApplication;
};
